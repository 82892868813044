import { SVGProps } from 'react';
const SvgUnitedKingdom = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <path
      fill="#F0F0F0"
      d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10Z"
    />
    <path
      fill="#0052B4"
      d="M4.067 5.912a9.964 9.964 0 0 0-1.723 3.48h5.203l-3.48-3.48Zm17.588 3.479a9.962 9.962 0 0 0-1.722-3.48l-3.48 3.48h5.202ZM2.344 14.609a9.965 9.965 0 0 0 1.723 3.48l3.48-3.48H2.344ZM18.088 4.067a9.964 9.964 0 0 0-3.48-1.723v5.203l3.48-3.48ZM5.912 19.933a9.964 9.964 0 0 0 3.48 1.723v-5.203l-3.48 3.48ZM9.391 2.344a9.965 9.965 0 0 0-3.48 1.723l3.48 3.48V2.344Zm5.218 19.311a9.964 9.964 0 0 0 3.48-1.722l-3.48-3.48v5.203Zm1.844-7.046 3.48 3.48a9.962 9.962 0 0 0 1.722-3.48h-5.202Z"
    />
    <path
      fill="#D80027"
      d="M21.915 10.696h-8.61V2.085a10.1 10.1 0 0 0-2.61 0v8.61h-8.61a10.1 10.1 0 0 0 0 2.61h8.61v8.61a10.115 10.115 0 0 0 2.61 0v-8.61h8.61a10.115 10.115 0 0 0 0-2.61Z"
    />
    <path
      fill="#D80027"
      d="m14.609 14.609 4.462 4.462c.205-.205.401-.42.588-.642l-3.82-3.82h-1.23Zm-5.218 0L4.93 19.07c.205.205.42.401.642.588l3.82-3.82v-1.23Zm0-5.218L4.93 4.93c-.205.205-.401.42-.588.642l3.82 3.82h1.23Zm5.218 0L19.07 4.93a10.21 10.21 0 0 0-.642-.588l-3.82 3.82v1.23Z"
    />
  </svg>
);
export default SvgUnitedKingdom;
