import { SVGProps } from 'react';
const SvgOutlinedCircleClose = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M1.963 12C1.963 6.457 6.457 1.963 12 1.963c5.544 0 10.037 4.494 10.037 10.037 0 5.544-4.493 10.037-10.037 10.037-5.543 0-10.037-4.493-10.037-10.037ZM12 4.037a7.963 7.963 0 1 0 0 15.926 7.963 7.963 0 0 0 0-15.926Z"
      clipRule="evenodd"
    />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M15.733 8.267a1.037 1.037 0 0 1 0 1.466L13.467 12l2.266 2.267a1.037 1.037 0 0 1-1.466 1.466L12 13.467l-2.267 2.266a1.037 1.037 0 0 1-1.466-1.466L10.533 12 8.267 9.733a1.037 1.037 0 1 1 1.466-1.466L12 10.533l2.267-2.266a1.037 1.037 0 0 1 1.466 0Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgOutlinedCircleClose;
