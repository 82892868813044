import { SVGProps } from 'react';
const SvgKeyhole = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <path
      fill="currentColor"
      d="M12.02.521A11.468 11.468 0 0 0 .553 11.991 11.468 11.468 0 0 0 12.02 23.458 11.468 11.468 0 0 0 23.488 11.99 11.468 11.468 0 0 0 12.02.521Zm-1.641 3.917h3.281c1.48 0 2.7 1.22 2.7 2.699v2.076c0 .671-.27 1.314-.745 1.789a.722.722 0 0 0 0 1.039c.475.475.744 1.118.744 1.79v3.015a2.712 2.712 0 0 1-2.699 2.697h-3.28c-1.48 0-2.7-1.218-2.7-2.697V13.83c0-.671.27-1.314.745-1.789h-.002a.722.722 0 0 0 .002-1.039h-.002a2.53 2.53 0 0 1-.742-1.79V7.138c0-1.48 1.22-2.7 2.699-2.7Zm0 1.796a.89.89 0 0 0-.9.903v2.076c0 .195.076.382.214.52a2.542 2.542 0 0 1 0 3.578.734.734 0 0 0-.214.52v3.015c0 .507.393.898.9.898h3.281c.507 0 .9-.391.9-.898V13.83a.734.734 0 0 0-.214-.52 2.542 2.542 0 0 1 0-3.578.734.734 0 0 0 .215-.52V7.138a.89.89 0 0 0-.9-.903zm1.34 8.778h.603a.9.9 0 0 1 .899.9.9.9 0 0 1-.899.899h-.603a.9.9 0 0 1-.9-.899.9.9 0 0 1 .9-.9z"
    />
  </svg>
);
export default SvgKeyhole;
