import { SVGProps } from 'react';
const SvgSquareInfo = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <path
      fill="currentColor"
      d="M0 7.14A7.14 7.14 0 0 1 7.14 0h9.72A7.14 7.14 0 0 1 24 7.14v9.72A7.14 7.14 0 0 1 16.86 24H7.14A7.14 7.14 0 0 1 0 16.86V7.14Z"
    />
    <path fill="#fff" d="M21.023 12.08a9.26 9.26 0 1 1-18.52 0 9.26 9.26 0 0 1 18.52 0Z" />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M11.641 10.99c.507 0 .918.41.918.917v4.419a.918.918 0 0 1-1.836 0v-4.419c0-.507.411-.918.918-.918Zm.123-4.072a1.236 1.236 0 1 0 0 2.472 1.236 1.236 0 0 0 0-2.472Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgSquareInfo;
