import { SVGProps } from 'react';
const SvgWalletReceive = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <path
      fill="currentColor"
      d="M18.69 11.53c-.57-.15-1.24-.23-2.04-.23-1.11 0-1.52.27-2.09.7-.03.02-.06.05-.09.08l-.95 1.01c-.8.84-2.24.85-3.04-.01l-.95-1a.647.647 0 0 0-.09-.08c-.57-.43-.98-.7-2.09-.7-.8 0-1.47.08-2.04.23-2.38.64-2.38 2.53-2.38 4.19v.93c0 2.51 0 5.35 5.35 5.35h7.44c3.55 0 5.35-1.8 5.35-5.35v-.93c0-1.66 0-3.55-2.38-4.19Zm-4.36 6.87H9.67c-.38 0-.69-.31-.69-.7 0-.39.31-.7.69-.7h4.66c.38 0 .69.31.69.7 0 .39-.31.7-.69.7ZM12.668 2.67a.67.67 0 0 0-1.34 0V4h1.35V2.67h-.01Z"
    />
    <path
      fill="currentColor"
      d="M19.21 8.42v1.7c-.04-.02-.09-.03-.13-.04h-.01c-.71-.19-1.5-.28-2.42-.28-1.54 0-2.27.45-2.93.95-.14.1-.24.2-.34.3l-.95 1.01c-.1.1-.26.16-.43.16a.58.58 0 0 1-.43-.17l-.98-1.03c-.1-.11-.22-.2-.25-.22-.72-.55-1.45-1-2.99-1-.92 0-1.71.09-2.43.28-.04.01-.09.02-.13.04v-1.7c0-2.07 0-4.42 4.42-4.42h2.12v3.45l-.65-.65a.676.676 0 0 0-.96.95l1.8 1.8c.01.01.02.01.02.02.06.05.13.1.2.13.09.03.17.05.26.05s.17-.02.26-.05c.08-.03.15-.08.22-.15l1.8-1.8a.676.676 0 0 0-.96-.95l-.65.65V4h2.12c4.42 0 4.42 2.35 4.42 4.42Z"
    />
  </svg>
);
export default SvgWalletReceive;
