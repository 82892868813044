import { SVGProps } from 'react';
const SvgGoogle = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 23 24"
    width="1em"
    height="1em"
    {...props}
  >
    <path
      fill="#D94F3D"
      d="M12.105 5.5c1.54 0 3.032.539 4.21 1.524l3.298-3.114a11.108 11.108 0 0 0-4.442-2.48 11.177 11.177 0 0 0-5.092-.246 11.131 11.131 0 0 0-4.664 2.04 11.009 11.009 0 0 0-3.25 3.894L5.884 9.96a6.512 6.512 0 0 1 2.384-3.225A6.604 6.604 0 0 1 12.105 5.5Z"
    />
    <path
      fill="#F2C042"
      d="M5.548 12a6.41 6.41 0 0 1 .336-2.04L2.165 7.12a10.89 10.89 0 0 0 0 9.764l3.72-2.842a6.42 6.42 0 0 1-.337-2.04Z"
    />
    <path
      fill="#5085ED"
      d="M22.748 10H12.155v4.5h6.003a5.367 5.367 0 0 1-2.298 3.081l3.69 2.82c2.358-2.098 3.744-5.51 3.198-10.4Z"
    />
    <path
      fill="#57A75C"
      d="M15.859 17.581a7.1 7.1 0 0 1-3.754.92 6.603 6.603 0 0 1-3.837-1.235 6.512 6.512 0 0 1-2.384-3.225l-3.719 2.841a11.045 11.045 0 0 0 4.092 4.462A11.18 11.18 0 0 0 12.105 23c2.72.074 5.371-.852 7.444-2.6l-3.69-2.819Z"
    />
  </svg>
);
export default SvgGoogle;
