import { SVGProps } from 'react';
const SvgCheckedCheckbox = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    data-name="Layer 1"
    viewBox="0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <rect width={24} height={24} fill="currentColor" rx={8.07} ry={8.07} />
    <path
      fill="none"
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2.5}
      d="m6.75 12.25 3.5 3.5 7-7.5"
    />
  </svg>
);
export default SvgCheckedCheckbox;
