import { SVGProps } from 'react';
const SvgIdCard = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 25 24"
    width="1em"
    height="1em"
    {...props}
  >
    <path
      fill="currentColor"
      d="M20.714 5H4.704c-1.1 0-1.99.9-1.99 2v10c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2Zm-5 3c1.1 0 2 .9 2 2s-.9 2-2 2-2-.9-2-2 .9-2 2-2Zm4 8h-8v-1c0-1.33 2.67-2 4-2s4 .67 4 2v1Z"
    />
  </svg>
);
export default SvgIdCard;
