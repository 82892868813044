import { SVGProps } from 'react';
const SvgArrowRightThickVariant = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 25 25"
    width="1em"
    height="1em"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M2.5 12.65c0-.98.762-1.774 1.702-1.774h11.909l-4.12-3.592a1.827 1.827 0 0 1-.213-2.499 1.655 1.655 0 0 1 2.398-.222l7.714 6.726c.387.337.61.835.61 1.36 0 .526-.223 1.024-.61 1.361l-7.714 6.726a1.655 1.655 0 0 1-2.398-.222 1.828 1.828 0 0 1 .213-2.5l4.12-3.59H4.202c-.94 0-1.702-.795-1.702-1.775Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgArrowRightThickVariant;
