import { useCallback } from 'react';
import type { AlertColor } from '@mui/material';
import type { OptionsObject, SnackbarMessage } from 'notistack';
import { useSnackbar } from 'notistack';
import { AlertSnackbar } from './AlertSnackbar';

export type TpPushAlert = Omit<OptionsObject, 'persist' | 'key'> & {
  dismissable?: boolean;
  key: string;
  message: SnackbarMessage;
  severity?: AlertColor | undefined;
};

export type TpUsePushAlert = (props: TpPushAlert) => void;

export function usePushAlert(): TpUsePushAlert {
  const { enqueueSnackbar } = useSnackbar();

  /**
   * must be in a useCallback to prevent multiple renders when used in a useEffect
   */
  return useCallback(
    ({
      autoHideDuration = 2000,
      dismissable = false,
      key,
      message,
      preventDuplicate,
      severity,
    }: TpPushAlert): void => {
      enqueueSnackbar(message, {
        autoHideDuration,
        content: (
          <AlertSnackbar
            data-qa={message}
            dismissable={dismissable}
            id={key}
            message={message}
            severity={severity}
          />
        ),
        key,
        persist: !autoHideDuration,
        preventDuplicate,
      });
    },
    [enqueueSnackbar]
  );
}
