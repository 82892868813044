import { SVGProps } from 'react';
const SvgProfile = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 25"
    width="1em"
    height="1em"
    {...props}
  >
    <path
      fill="currentColor"
      d="M12 12.107a5 5 0 1 0 0-9.999 5 5 0 0 0 0 10Zm3.5 1.25h-.652a6.808 6.808 0 0 1-5.696 0H8.5a5.251 5.251 0 0 0-5.25 5.25v1.625c0 1.035.84 1.875 1.875 1.875h13.75c1.035 0 1.875-.84 1.875-1.875v-1.625a5.251 5.251 0 0 0-5.25-5.25Z"
    />
  </svg>
);
export default SvgProfile;
