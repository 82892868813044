import type { TpFeatureUI, TpRoute } from '@noah-labs/core-web-ui/src/types';
import type { AuthGroups } from '@noah-labs/noah-schema';

export type TpAuthRoute = {
  feature?: TpFeatureUI;
  groups?: Set<AuthGroups>;
  path: string;
};

type PpCreateAuthRoute = Omit<TpAuthRoute, 'path'> & {
  base: string;
  id?: string;
  segments: string[] | null;
  title: TpRoute['title'];
};

export function createRoute({
  base,
  id,
  segments,
  ...rest
}: PpCreateAuthRoute): TpRoute & TpAuthRoute {
  const baseId = [base, id].filter(Boolean).join('-');

  if (!segments) {
    return {
      ...rest,
      id: baseId,
      path: `/${base}`,
    };
  }
  return {
    ...rest,
    id: id ? baseId : `${base}-${segments.join('-')}`,
    path: `/${base}/${segments.join('/')}`,
  };
}
