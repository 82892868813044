/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { TpApiPaths, TpCFProxyPaths } from './api';
import { getAuthDomains } from './getAuthDomains';
import { createPath, createUrl } from './urlUtils';

export const assetsHost = 'assets.noah.com';

/**
 * URL: https://inbound.preprod.noah.com:3000/foo
 * BaseUrl: https://inbound.preprod.noah.com:3000
 * Host: inbound.preprod.noah.com:3000
 * Root: inbound.preprod.noah.com
 * Domain: preprod.noah.com
 * Subdomain: preprod
 * Path: /graphql
 * Slug: /something/graphql
 */

type PpGetDomains = {
  authSandbox: boolean;
  localPort?: string;
  sub: string;
};
export function getDomains({ authSandbox, localPort, sub }: PpGetDomains) {
  const subLower = sub.toLowerCase();

  const graphqlPath = `/${TpCFProxyPaths.graphql}`;
  const graphqlRealtimePath = `/${TpCFProxyPaths.graphqlRt}`;

  const api = {
    apiBaseUrl: createUrl([`${subLower}.noah.io`, TpApiPaths.apiV1]),
    apiHost: `${subLower}.noah.io`,
    graphqlHost: `gql.${subLower}.noah.io`,
  };

  const checkoutWebhookUrl = createUrl([api.apiHost, TpApiPaths.apiV1, TpApiPaths.checkout]);

  const authDomains = getAuthDomains({
    apiHost: api.apiHost,
    localPort,
    sandbox: authSandbox,
    sub,
  });

  switch (subLower) {
    case 'prod': {
      const appHost = 'app.noah.com';
      const businessHost = 'api.noah.com';
      const appUrl = createUrl([appHost]);
      const appWsUrl = createUrl([appHost], 'wss');
      const businessApiUrl = createUrl([businessHost, TpApiPaths.v1]);
      return {
        analytics: {
          apiHost: createPath([appHost, TpCFProxyPaths.analyticsApi]),
          cdnUrl: createUrl([appHost, TpCFProxyPaths.analyticsCDN]),
        },
        api,
        auth: authDomains,
        businessApi: {
          businessApiUrl,
          businessHost,
        },
        checkoutWebhookUrl,
        lnAddress: 'noah.me',
        mockServer: {
          grpcDomain: '',
          grpcUrl: '',
          httpDomain: '',
          httpUrl: '',
        },
        signing: {
          url: 'https://enclave.signing1.com',
        },
        webapp: {
          graphqlBaseUrl: appUrl,
          graphqlBaseWsUrl: appWsUrl,
          graphqlPath,
          graphqlRealtimePath,
          // graphqlUrl is currently only used in tests for directly calling or intercepting graphql calls
          graphqlUrl: createPath([appUrl, TpCFProxyPaths.graphql]),
          host: 'app.noah.com',
          s3AllowedOrigins: [appUrl],
          url: appUrl,
        },
      };
    }

    default: {
      const appHost = `${subLower}.noah.com`;
      const businessHost = `api.${subLower}.noah.com`;
      const businessApiUrl = createUrl([businessHost, TpApiPaths.v1]);
      let appUrl = createUrl([appHost]);
      let appWsUrl = createUrl([appHost], 'wss');

      if (localPort) {
        appUrl = `http://localhost:${localPort}`;
        appWsUrl = `ws://localhost:${localPort}`;
      }

      return {
        analytics: {
          apiHost: createPath([appHost, TpCFProxyPaths.analyticsApi]),
          cdnUrl: createUrl([appHost, TpCFProxyPaths.analyticsCDN]),
        },
        api,
        auth: authDomains,
        businessApi: {
          businessApiUrl,
          businessHost,
        },
        checkoutWebhookUrl,
        lnAddress: api.apiHost,
        mockServer: {
          // All accounts use mock servers in TestAccount on grpcUrl and httpUrl
          grpcDomain: 'grpcmockserver.test.noah.io',
          grpcUrl: 'grpcmockserver.test.noah.io:443',
          httpDomain: 'httpmockserver.test.noah.io',
          httpUrl: 'https://httpmockserver.test.noah.io',
        },
        signing: {
          url: 'https://enclave.signing2.com',
        },
        webapp: {
          graphqlBaseUrl: createUrl([appHost]),
          graphqlBaseWsUrl: appWsUrl,
          graphqlPath,
          graphqlRealtimePath,
          // graphqlUrl is currently only used in tests for directly calling or intercepting graphql calls
          graphqlUrl: createPath([appUrl, TpCFProxyPaths.graphql]),
          host: appHost,
          s3AllowedOrigins: [appUrl, 'http://localhost:8000'],
          url: appUrl,
        },
      };
    }
  }
}
