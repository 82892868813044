import type { AxiosResponse } from 'axios';
import axios from 'axios';
import type {
  TpAddFaceResponse,
  TpAddIdRequest,
  TpAddIdResponse,
  TpFetchOnboardingStatusResponse,
  TpIncodeClientConfig,
  TpOcrData,
  TpOnboardingCompleteResponse,
  TpProcessFaceResponse,
} from './types';

const incodeApiHeaders = {
  Accept: 'application/json',
  'api-version': '1.0',
  'Content-Type': 'application/json',
};

function getHeaders(token: string): { headers: Record<string, string> } {
  return {
    headers: {
      ...incodeApiHeaders,
      'X-Incode-Hardware-Id': token,
    },
  };
}

export function addFrontId(
  config: TpIncodeClientConfig,
  request: TpAddIdRequest,
  onlyFront?: boolean
): Promise<AxiosResponse<TpAddIdResponse>> {
  const { baseURL, token } = config;
  return axios.post(`/omni/add/front-id/v2?onlyFront=${onlyFront ? 'true' : 'false'}`, request, {
    baseURL,
    ...getHeaders(token),
  });
}

export function addBackId(
  config: TpIncodeClientConfig,

  request: TpAddIdRequest,
  retry?: boolean
): Promise<AxiosResponse<TpAddIdResponse>> {
  const { baseURL, token } = config;
  return axios.post(`/omni/add/back-id/v2?retry=${retry ? 'true' : 'false'}`, request, {
    baseURL,
    ...getHeaders(token),
  });
}

export function processId(
  config: TpIncodeClientConfig
): Promise<AxiosResponse<{ success: boolean }>> {
  const { baseURL, token } = config;
  return axios.post('/omni/process/id', undefined, {
    baseURL,
    ...getHeaders(token),
  });
}

export function fetchOcrData(config: TpIncodeClientConfig): Promise<AxiosResponse<TpOcrData>> {
  const { baseURL, token } = config;
  return axios.get('/omni/get/ocr-data', {
    baseURL,
    ...getHeaders(token),
  });
}

export function addFace(
  config: TpIncodeClientConfig,
  request: TpAddIdRequest
): Promise<AxiosResponse<TpAddFaceResponse>> {
  const { baseURL, token } = config;
  return axios.post(`/omni/add/face/third-party`, request, {
    baseURL,
    ...getHeaders(token),
  });
}

export function processFace(
  config: TpIncodeClientConfig
): Promise<AxiosResponse<TpProcessFaceResponse>> {
  const { baseURL, token } = config;
  return axios.post('/omni/process/face', undefined, {
    baseURL,
    ...getHeaders(token),
  });
}

export function fetchOnboardingStatus(
  config: TpIncodeClientConfig
): Promise<AxiosResponse<TpFetchOnboardingStatusResponse>> {
  const { baseURL, token } = config;
  return axios.get('/omni/get/onboarding/status', {
    baseURL,
    ...getHeaders(token),
  });
}

export function onboardingComplete(
  config: TpIncodeClientConfig
): Promise<AxiosResponse<TpOnboardingCompleteResponse>> {
  const { baseURL, token } = config;
  return axios.get('/omni/finish-status', {
    baseURL,
    ...getHeaders(token),
  });
}
