import type React from 'react';
import { useEffect, useState } from 'react';
import { usePushAlert } from '@noah-labs/core-web-ui/src/alerts/usePushAlert';
import { getErrorMessage } from '@noah-labs/core-web-ui/src/tools/getErrorMessage';
import { AuthErrorType, getPropertyByPath } from '@noah-labs/fe-shared-data-access-auth';
import { logger } from '@noah-labs/shared-logger/src/browser/logger';
import { useHistory, useLocation } from 'react-router-dom';
import {
  AlertAuthUnknown,
  AlertMessage,
  AlertSignUpEmailAlreadyExists,
} from '../components/Alerts';

export type TpUseAuthError = {
  AuthErrorScene: React.ReactElement | null;
};
export type PpUseAuthError = {
  error: unknown;
};
export function useAuthError({ error }: PpUseAuthError): TpUseAuthError {
  const [authErrorScene, setAuthErrorScene] = useState<React.ReactElement | null>(null);
  const pushAlert = usePushAlert();
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (!error) {
      setAuthErrorScene(null);
      return;
    }
    logger.error(error);
    /**
     * Auth Error handler
     */
    const errMsg = getErrorMessage(error);
    const errorType = getPropertyByPath(error, ['type']);

    switch (errorType) {
      case AuthErrorType.IdentifierExists:
        pushAlert(AlertSignUpEmailAlreadyExists);
        return;
      case AuthErrorType.Unspecified:
        pushAlert(AlertMessage(errMsg));
        return;
      default:
        // Do nothing
        break;
    }

    /**
     * Seems to be an unknown error message, display default generic message
     */
    pushAlert(AlertAuthUnknown);
    setAuthErrorScene(null);
  }, [error, history, location.pathname, pushAlert]);

  return { AuthErrorScene: authErrorScene };
}
