import type { Country, CountryCode } from '@noah-labs/noah-schema';
import type { TpCountriesForSelect, TpCountryFilterFunction, TpCountryWithCode } from './types';

function compareCountryName(a: Country, b: Country): number {
  return a.name.localeCompare(b.name, undefined, { sensitivity: 'base' });
}

let sortedCountriesCache: Array<TpCountryWithCode> | undefined;
async function sortedCountries(): Promise<Array<TpCountryWithCode>> {
  if (sortedCountriesCache) {
    return sortedCountriesCache;
  }
  const CountriesList = await import('../countries-list');
  const countryArray = Object.entries(CountriesList.default.countries).map(([key, country]) => ({
    ...country,
    code: key as CountryCode,
  }));
  sortedCountriesCache = countryArray.sort(compareCountryName);
  return sortedCountriesCache;
}

export async function countriesForSelect(
  filterFn?: TpCountryFilterFunction
): Promise<TpCountriesForSelect> {
  const countryArray = await sortedCountries();
  return countryArray.reduce<TpCountriesForSelect>((acc, country) => {
    if (!filterFn || filterFn(country.code)) {
      acc.push({
        currency: country.currency,
        label: country.name,
        symbol: country.emoji,
        value: country.code,
      });
    }
    return acc;
  }, []);
}

export async function phoneNumbersForSelect(
  filterFn?: TpCountryFilterFunction
): Promise<TpCountriesForSelect> {
  const countryArray = await sortedCountries();
  return countryArray.reduce<TpCountriesForSelect>((acc, country) => {
    if (!filterFn || filterFn(country.code)) {
      acc.push({
        label: country.name,
        labelStart: `+${country.phone}`,
        symbol: country.emoji,
        value: country.phone,
      });
    }
    return acc;
  }, []);
}
