import type { UseQueryOptions, UseQueryResult } from 'react-query';
import { useQuery } from 'react-query';
import { countriesForSelect, phoneNumbersForSelect } from './query-functions';
import type { TpCountriesForSelect, TpCountryFilterFunction } from './types';

export function useCountriesForSelect(
  filterFn?: TpCountryFilterFunction,
  options?: UseQueryOptions<TpCountriesForSelect>
): UseQueryResult<TpCountriesForSelect> {
  return useQuery({
    queryFn: () => countriesForSelect(filterFn),
    queryKey: ['CountriesForSelect', filterFn],
    ...options,
  });
}

export function usePhoneNumbersForSelect(
  filterFn?: TpCountryFilterFunction,
  options?: UseQueryOptions<TpCountriesForSelect>
): UseQueryResult<TpCountriesForSelect> {
  return useQuery({
    queryFn: () => phoneNumbersForSelect(filterFn),
    queryKey: ['PhoneNumbersForSelect', filterFn],
    ...options,
  });
}
