import { SVGProps } from 'react';
const SvgCircleTick = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 25"
    width="1em"
    height="1em"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12 2.107c-5.523 0-10 4.478-10 10 0 5.523 4.477 10 10 10s10-4.477 10-10c0-5.522-4.477-10-10-10Zm4.334 8.31a.91.91 0 1 0-1.396-1.164l-3.909 4.69-2.023-2.024a.91.91 0 0 0-1.285 1.286l2.727 2.727a.908.908 0 0 0 1.341-.061l4.545-5.455Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgCircleTick;
