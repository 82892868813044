import { SVGProps } from 'react';
const SvgNoahCurrency = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M4.066 20a3.187 3.187 0 0 1-2.996-2.44C-.934 9.176.292 6.465 1.388 5.154a4.013 4.013 0 0 1 3.276-1.483h.02c1.973.11 3.374 1.626 5.493 3.917l.008.01.028.03c1.112 1.202 2.366 2.56 3.611 3.591 1.5 1.244 2.275 1.425 2.542 1.446.054.003.108.007.161.013l.014-.001c.255-.453.778-1.818.901-5.387a3.177 3.177 0 0 1 1.686-2.703 3.196 3.196 0 0 1 3.191.11 3.176 3.176 0 0 1 1.495 2.812c-.18 5.201-1.143 8.312-3.142 10.09a5.566 5.566 0 0 1-3.976 1.436c-.074 0-.148-.006-.222-.011l-.04-.003a3.694 3.694 0 0 1-.075-.007 3.242 3.242 0 0 1-.348 0l-.136-.008c-3.554-.272-6.63-3.116-9.201-5.83.135.821.328 1.785.598 2.91a3.176 3.176 0 0 1-.635 2.746A3.197 3.197 0 0 1 4.066 20Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgNoahCurrency;
