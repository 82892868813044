/* eslint-disable react/no-multi-comp */
import { Fingerprint } from '@mui/icons-material';
import type { SvgIconProps } from '@mui/material';
import { SvgIcon } from '@mui/material';
import {
  Access,
  AccountLimits,
  Activity,
  Add,
  Analytics,
  AnalyticsActive,
  AnalyticsNormal,
  Apple,
  ApplePay,
  ArrowDownThick,
  ArrowRight,
  ArrowRightThick,
  ArrowRightThickVariant,
  ArrowUpRightCircleThick,
  ArrowUpThick,
  Attachment,
  BackToTop,
  Bank,
  Banknote,
  BellCircle,
  Bitcoin,
  Bitrefill,
  Borrow,
  Building,
  Calendar,
  Check,
  Checkbox,
  CheckedCheckbox,
  CheckedRadio,
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  ChevronUp,
  CircleFacebook,
  CircleLinkedin,
  CircleMail,
  CircleTelegram,
  CircleTick,
  CircleTwitter,
  CircleWhatsapp,
  ClockOutline,
  Close,
  CloseCircle,
  CloseCircleThick,
  CloseDialog,
  CloseThick,
  Code,
  Contact,
  Copy,
  CopySquare,
  CreditCard,
  Crown,
  Customize,
  Dai,
  Discord,
  DiscordSquare,
  Document,
  DollarActive,
  DollarCircle,
  DollarNormal,
  Download,
  Edit,
  Egg,
  EllipsisMenu,
  Enlarge,
  Eps,
  Ethereum,
  EuropeanUnion,
  Exchange,
  Eye,
  FacebookSquare,
  Faq,
  Fb,
  Filters,
  Gift,
  Giropay,
  Globe,
  Google,
  GooglePay,
  Grow,
  Home,
  IdCard,
  ImportExport,
  Info,
  Instagram,
  IosShare,
  Key,
  Keyhole,
  Lightning,
  LightningCircle,
  Link,
  Linkedin,
  Lock,
  Logout,
  Mastercard,
  Medium,
  Menu,
  Minus,
  Moonpay,
  More,
  NoahCircle,
  NoahCurrency,
  NoahSquare,
  Notification,
  OpenInWindow,
  OutlinedCircleClose,
  PaymentsActive,
  PaymentsNormal,
  Pending,
  Placeholder,
  Plant,
  Plus,
  PlusCircle,
  PlusCircleThick,
  PlusThick,
  Polygon,
  Profile,
  Radio,
  Receive,
  Referral,
  Rewards,
  Rocket,
  Scan,
  Search,
  Sell,
  Send,
  Share,
  ShoppingCart,
  Show,
  Sofort,
  SquareInfo,
  SquareTick,
  SquareWarning,
  StarBadge,
  Stopwatch,
  Support,
  SwitchArrows,
  Symbol,
  Telegram,
  TelegramSquare,
  Time,
  Time1,
  TimeCircle,
  Transactions,
  Tron,
  Twitter,
  UnitedKingdom,
  UnitedStates,
  Usdcoin,
  Usdt,
  Visa,
  Wallet,
  WalletActive,
  WalletCircle,
  WalletNormal,
  WalletReceive,
  Warning,
  Whatsapp,
  WhatsappSquare,
  WithdrawCircle,
} from './generated';

type PpSvgIcon = SvgIconProps & {
  component: (props: React.SVGProps<SVGSVGElement>) => React.ReactElement;
};

/* MUI's SvgIcon adds `fill="currentColor"` to all icons by default. It breaks some of our custom icons. This separate component is needed to override `fill` attribute  value. */
function SvgFillNoneIcon({ sx, ...props }: PpSvgIcon): React.ReactElement {
  return <SvgIcon {...props} sx={{ fill: 'none', ...sx }} />;
}

export function AccessIcon(props: SvgIconProps): React.ReactElement {
  return <SvgFillNoneIcon {...props} component={Access} data-qa="AccessIcon" />;
}
export function ActivityIcon(props: SvgIconProps): React.ReactElement {
  return <SvgFillNoneIcon {...props} component={Activity} data-qa="ActivityIcon" />;
}
export function AddIcon(props: SvgIconProps): React.ReactElement {
  return <SvgFillNoneIcon {...props} component={Add} data-qa="AddIcon" />;
}
export function AnalyticsActiveIcon(props: SvgIconProps): React.ReactElement {
  return <SvgFillNoneIcon {...props} component={AnalyticsActive} data-qa="AnalyticsActiveIcon" />;
}
export function AnalyticsNormalIcon(props: SvgIconProps): React.ReactElement {
  return <SvgFillNoneIcon {...props} component={AnalyticsNormal} data-qa="AnalyticsNormalIcon" />;
}
export function AnalyticsIcon(props: SvgIconProps): React.ReactElement {
  return <SvgFillNoneIcon {...props} component={Analytics} data-qa="AnalyticsIcon" />;
}
export function ApplePayIcon(props: SvgIconProps): React.ReactElement {
  return <SvgFillNoneIcon {...props} component={ApplePay} data-qa="ApplePayIcon" />;
}
export function ArrowDownIcon({ sx, ...rest }: SvgIconProps): React.ReactElement {
  return (
    <SvgFillNoneIcon
      sx={{ transform: 'rotate(90deg)', ...sx }}
      {...rest}
      component={ArrowRight}
      data-qa="ArrowDownIcon"
    />
  );
}
export function ArrowLeftIcon({ sx, ...rest }: SvgIconProps): React.ReactElement {
  return (
    <SvgFillNoneIcon
      sx={{ transform: 'rotate(180deg)', ...sx }}
      {...rest}
      component={ArrowRight}
      data-qa="ArrowLeftIcon"
    />
  );
}
export function ArrowUpIcon({ sx, ...rest }: SvgIconProps): React.ReactElement {
  return (
    <SvgFillNoneIcon
      sx={{ transform: 'rotate(-90deg)', ...sx }}
      {...rest}
      component={ArrowRight}
      data-qa="ArrowUpIcon"
    />
  );
}
export function ArrowRightIcon(props: SvgIconProps): React.ReactElement {
  return <SvgFillNoneIcon {...props} component={ArrowRight} data-qa="ArrowRightIcon" />;
}
export function ArrowDownThickIcon(props: SvgIconProps): React.ReactElement {
  return <SvgIcon {...props} component={ArrowDownThick} data-qa="ArrowDownThickIcon" />;
}
export function ArrowRightThickIcon(props: SvgIconProps): React.ReactElement {
  return <SvgIcon {...props} component={ArrowRightThick} data-qa="ArrowRightThickIcon" />;
}
export function ArrowUpThickIcon(props: SvgIconProps): React.ReactElement {
  return <SvgIcon {...props} component={ArrowUpThick} data-qa="ArrowUpThickIcon" />;
}
export function AttachmentIcon(props: SvgIconProps): React.ReactElement {
  return <SvgFillNoneIcon {...props} component={Attachment} data-qa="AttachmentIcon" />;
}
export function BackToTopIcon(props: SvgIconProps): React.ReactElement {
  return <SvgFillNoneIcon {...props} component={BackToTop} data-qa="BackToTopIcon" />;
}
export function BanknoteIcon(props: SvgIconProps): React.ReactElement {
  return <SvgFillNoneIcon {...props} component={Banknote} data-qa="BanknoteIcon" />;
}
export function BitcoinIcon(props: SvgIconProps): React.ReactElement {
  return <SvgFillNoneIcon {...props} component={Bitcoin} data-qa="BitcoinIcon" />;
}
export function BitrefillIcon(props: SvgIconProps): React.ReactElement {
  return <SvgFillNoneIcon {...props} component={Bitrefill} data-qa="BitrefillIcon" />;
}
export function BorrowIcon(props: SvgIconProps): React.ReactElement {
  return <SvgFillNoneIcon {...props} component={Borrow} data-qa="BorrowIcon" />;
}
export function BuildingIcon(props: SvgIconProps): React.ReactElement {
  return <SvgFillNoneIcon {...props} component={Building} data-qa="BuildingIcon" />;
}
export function CalendarIcon(props: SvgIconProps): React.ReactElement {
  return <SvgFillNoneIcon {...props} component={Calendar} data-qa="CalendarIcon" />;
}
export function CircleFacebookIcon(props: SvgIconProps): React.ReactElement {
  return <SvgFillNoneIcon {...props} component={CircleFacebook} data-qa="CircleFacebookIcon" />;
}
export function CircleLinkedinIcon(props: SvgIconProps): React.ReactElement {
  return <SvgFillNoneIcon {...props} component={CircleLinkedin} data-qa="CircleLinkedinIcon" />;
}
export function CircleMailIcon(props: SvgIconProps): React.ReactElement {
  return <SvgFillNoneIcon {...props} component={CircleMail} data-qa="CircleMailIcon" />;
}
export function CircleTelegramIcon(props: SvgIconProps): React.ReactElement {
  return <SvgFillNoneIcon {...props} component={CircleTelegram} data-qa="CircleTelegramIcon" />;
}
export function CircleTwitterIcon(props: SvgIconProps): React.ReactElement {
  return <SvgFillNoneIcon {...props} component={CircleTwitter} data-qa="CircleTwitterIcon" />;
}
export function CircleWhatsappIcon(props: SvgIconProps): React.ReactElement {
  return <SvgFillNoneIcon {...props} component={CircleWhatsapp} data-qa="CircleWhatsappIcon" />;
}
export function CircleTickIcon(props: SvgIconProps): React.ReactElement {
  return <SvgFillNoneIcon {...props} component={CircleTick} data-qa="CircleTickIcon" />;
}
export function CheckboxIcon(props: SvgIconProps): React.ReactElement {
  return <SvgIcon {...props} component={Checkbox} data-qa="Checkbox" />;
}
export function CheckedCheckboxIcon(props: SvgIconProps): React.ReactElement {
  return <SvgIcon {...props} component={CheckedCheckbox} data-qa="CheckedCheckboxIcon" />;
}
export function CheckedRadioIcon(props: SvgIconProps): React.ReactElement {
  return <SvgIcon {...props} component={CheckedRadio} data-qa="CheckedRadioIcon" />;
}
export function ChevronDownIcon(props: SvgIconProps): React.ReactElement {
  return <SvgFillNoneIcon {...props} component={ChevronDown} data-qa="ChevronDownIcon" />;
}
export function ChevronLeftIcon(props: SvgIconProps): React.ReactElement {
  return <SvgFillNoneIcon {...props} component={ChevronLeft} data-qa="ChevronLeftIcon" />;
}
export function ChevronRightIcon(props: SvgIconProps): React.ReactElement {
  return <SvgFillNoneIcon {...props} component={ChevronRight} data-qa="ChevronRightIcon" />;
}
export function ChevronUpIcon(props: SvgIconProps): React.ReactElement {
  return <SvgFillNoneIcon {...props} component={ChevronUp} data-qa="ChevronUpIcon" />;
}
export function ClockOutlineIcon(props: SvgIconProps): React.ReactElement {
  return <SvgFillNoneIcon {...props} component={ClockOutline} data-qa="ClockOutlineIcon" />;
}
export function CloseCircleIcon(props: SvgIconProps): React.ReactElement {
  return <SvgFillNoneIcon {...props} component={CloseCircle} data-qa="CloseCircleIcon" />;
}
export function CloseIcon(props: SvgIconProps): React.ReactElement {
  return <SvgFillNoneIcon {...props} component={Close} data-qa="CloseIcon" />;
}
export function CloseDialogIcon(props: SvgIconProps): React.ReactElement {
  return <SvgIcon {...props} component={CloseDialog} data-qa="CloseDialogIcon" />;
}
export function CloseThickIcon(props: SvgIconProps): React.ReactElement {
  return <SvgIcon {...props} component={CloseThick} data-qa="CloseThickIcon" />;
}
export function CodeIcon(props: SvgIconProps): React.ReactElement {
  return <SvgFillNoneIcon {...props} component={Code} data-qa="CodeIcon" />;
}
export function CopyIcon(props: SvgIconProps): React.ReactElement {
  return <SvgFillNoneIcon {...props} component={Copy} data-qa="CopyIcon" />;
}
export function ContactIcon(props: SvgIconProps): React.ReactElement {
  return <SvgFillNoneIcon {...props} component={Contact} data-qa="ContactIcon" />;
}
export function CustomizeIcon(props: SvgIconProps): React.ReactElement {
  return <SvgFillNoneIcon {...props} component={Customize} data-qa="CustomizeIcon" />;
}
export function DaiIcon(props: SvgIconProps): React.ReactElement {
  return <SvgFillNoneIcon {...props} component={Dai} data-qa="DaiIcon" />;
}
export function DiscordIcon(props: SvgIconProps): React.ReactElement {
  return <SvgFillNoneIcon {...props} component={Discord} data-qa="DiscordIcon" />;
}
export function DocumentIcon(props: SvgIconProps): React.ReactElement {
  return <SvgFillNoneIcon {...props} component={Document} data-qa="DocumentIcon" />;
}
export function DollarActiveIcon(props: SvgIconProps): React.ReactElement {
  return <SvgFillNoneIcon {...props} component={DollarActive} data-qa="DollarActiveIcon" />;
}
export function DollarCircleIcon(props: SvgIconProps): React.ReactElement {
  return <SvgFillNoneIcon {...props} component={DollarCircle} data-qa="DollarCircleIcon" />;
}
export function DollarNormalIcon(props: SvgIconProps): React.ReactElement {
  return <SvgFillNoneIcon {...props} component={DollarNormal} data-qa="DollarNormalIcon" />;
}
export function DownloadIcon(props: SvgIconProps): React.ReactElement {
  return <SvgFillNoneIcon {...props} component={Download} data-qa="DownloadIcon" />;
}
export function EditIcon(props: SvgIconProps): React.ReactElement {
  return <SvgFillNoneIcon {...props} component={Edit} data-qa="EditIcon" />;
}
export function EpsIcon(props: SvgIconProps): React.ReactElement {
  return <SvgFillNoneIcon {...props} component={Eps} data-qa="EpsIcon" />;
}
export function EggIcon(props: SvgIconProps): React.ReactElement {
  return <SvgFillNoneIcon {...props} component={Egg} data-qa="EggIcon" />;
}
export function EuropeanUnionIcon(props: SvgIconProps): React.ReactElement {
  return <SvgFillNoneIcon {...props} component={EuropeanUnion} data-qa="EuropeanUnionIcon" />;
}
export function ExchangeIcon(props: SvgIconProps): React.ReactElement {
  return <SvgFillNoneIcon {...props} component={Exchange} data-qa="ExchangeIcon" />;
}
export function EyeIcon(props: SvgIconProps): React.ReactElement {
  return <SvgFillNoneIcon {...props} component={Eye} data-qa="EyeIcon" />;
}
export function FaqIcon(props: SvgIconProps): React.ReactElement {
  return <SvgFillNoneIcon {...props} component={Faq} data-qa="FaqIcon" />;
}
export function FbIcon(props: SvgIconProps): React.ReactElement {
  return <SvgFillNoneIcon {...props} component={Fb} data-qa="FbIcon" />;
}
export function FiltersIcon(props: SvgIconProps): React.ReactElement {
  return <SvgFillNoneIcon {...props} component={Filters} data-qa="FiltersIcon" />;
}
export function GiftIcon(props: SvgIconProps): React.ReactElement {
  return <SvgFillNoneIcon {...props} component={Gift} data-qa="GiftIcon" />;
}
export function GiropayIcon(props: SvgIconProps): React.ReactElement {
  return <SvgFillNoneIcon {...props} component={Giropay} data-qa="GiropayIcon" />;
}
export function GlobeIcon(props: SvgIconProps): React.ReactElement {
  return <SvgFillNoneIcon {...props} component={Globe} data-qa="GlobeIcon" />;
}
export function CrownIcon(props: SvgIconProps): React.ReactElement {
  return <SvgFillNoneIcon {...props} component={Crown} data-qa="CrownIcon" />;
}
export function RocketIcon(props: SvgIconProps): React.ReactElement {
  return <SvgFillNoneIcon {...props} component={Rocket} data-qa="RocketIcon" />;
}
export function GooglePayIcon(props: SvgIconProps): React.ReactElement {
  return <SvgFillNoneIcon {...props} component={GooglePay} data-qa="GooglePayIcon" />;
}
export function GrowIcon(props: SvgIconProps): React.ReactElement {
  return <SvgFillNoneIcon {...props} component={Grow} data-qa="GrowIcon" />;
}
export function HomeIcon(props: SvgIconProps): React.ReactElement {
  return <SvgFillNoneIcon {...props} component={Home} data-qa="HomeIcon" />;
}
export function ImportExportIcon(props: SvgIconProps): React.ReactElement {
  return <SvgFillNoneIcon {...props} component={ImportExport} data-qa="ImportExportIcon" />;
}
export function ImportExportHorizontalIcon({ sx, ...rest }: SvgIconProps): React.ReactElement {
  return (
    <SvgFillNoneIcon
      {...rest}
      component={ImportExport}
      data-qa="ImportExportIcon"
      sx={{ transform: 'rotate(90deg)', ...sx }}
    />
  );
}
export function IdCardIcon(props: SvgIconProps): React.ReactElement {
  return <SvgFillNoneIcon {...props} component={IdCard} data-qa="IdCardIcon" />;
}
export function InfoIcon(props: SvgIconProps): React.ReactElement {
  return <SvgFillNoneIcon {...props} component={Info} data-qa="InfoIcon" />;
}
export function IosShareIcon(props: SvgIconProps): React.ReactElement {
  return <SvgFillNoneIcon {...props} component={IosShare} data-qa="IosShareIcon" />;
}
export function KeyIcon(props: SvgIconProps): React.ReactElement {
  return <SvgIcon {...props} component={Key} data-qa="KeyIcon" />;
}
export function KeyholeIcon(props: SvgIconProps): React.ReactElement {
  return <SvgIcon {...props} component={Keyhole} data-qa="KeyholeIcon" />;
}
export function LightningCircleIcon(props: SvgIconProps): React.ReactElement {
  return <SvgFillNoneIcon {...props} component={LightningCircle} data-qa="LightningCircleIcon" />;
}
export function DiscordSquareIcon(props: SvgIconProps): React.ReactElement {
  return <SvgIcon {...props} component={DiscordSquare} data-qa="DiscordSquareIcon" />;
}
export function InstagramIcon(props: SvgIconProps): React.ReactElement {
  return <SvgIcon {...props} component={Instagram} data-qa="InstagramIcon" />;
}
export function LightningIcon(props: SvgIconProps): React.ReactElement {
  return <SvgFillNoneIcon {...props} component={Lightning} data-qa="LightningIcon" />;
}
export function LockIcon(props: SvgIconProps): React.ReactElement {
  return <SvgFillNoneIcon {...props} component={Lock} data-qa="LockIcon" />;
}
export function LinkedinIcon(props: SvgIconProps): React.ReactElement {
  return <SvgIcon {...props} component={Linkedin} data-qa="LinkedinIcon" />;
}
export function LogoutIcon(props: SvgIconProps): React.ReactElement {
  return <SvgFillNoneIcon {...props} component={Logout} data-qa="LogoutIcon" />;
}
export function MastercardIcon(props: SvgIconProps): React.ReactElement {
  return <SvgFillNoneIcon {...props} component={Mastercard} data-qa="MastercardIcon" />;
}
export function MediumIcon(props: SvgIconProps): React.ReactElement {
  return <SvgFillNoneIcon {...props} component={Medium} data-qa="MediumIcon" />;
}
export function MenuIcon(props: SvgIconProps): React.ReactElement {
  return <SvgFillNoneIcon {...props} component={Menu} data-qa="MenuIcon" />;
}
export function MinusIcon(props: SvgIconProps): React.ReactElement {
  return <SvgFillNoneIcon {...props} component={Minus} data-qa="MinusIcon" />;
}
export function MoreIcon(props: SvgIconProps): React.ReactElement {
  return <SvgFillNoneIcon {...props} component={More} data-qa="MoreIcon" />;
}
export function MoonpayIcon(props: SvgIconProps): React.ReactElement {
  return <SvgFillNoneIcon {...props} component={Moonpay} data-qa="MoonpayIcon" />;
}
export function NoahCurrencyIcon(props: SvgIconProps): React.ReactElement {
  return <SvgFillNoneIcon {...props} component={NoahCurrency} data-qa="NoahCurrencyIcon" />;
}
export function NoahSquareIcon(props: SvgIconProps): React.ReactElement {
  return <SvgFillNoneIcon {...props} component={NoahSquare} data-qa="NoahSquareIcon" />;
}
export function OpenInWindowIcon(props: SvgIconProps): React.ReactElement {
  return <SvgIcon {...props} component={OpenInWindow} data-qa="OpenInWindowIcon" />;
}
export function PaymentsActiveIcon(props: SvgIconProps): React.ReactElement {
  return <SvgFillNoneIcon {...props} component={PaymentsActive} data-qa="PaymentsActiveIcon" />;
}
export function PaymentsNormalIcon(props: SvgIconProps): React.ReactElement {
  return <SvgFillNoneIcon {...props} component={PaymentsNormal} data-qa="PaymentsNormalIcon" />;
}
export function PlaceholderIcon(props: SvgIconProps): React.ReactElement {
  return <SvgFillNoneIcon {...props} component={Placeholder} data-qa="PlaceholderIcon" />;
}
export function PlantIcon(props: SvgIconProps): React.ReactElement {
  return <SvgFillNoneIcon {...props} component={Plant} data-qa="PlantIcon" />;
}
export function PlusCircleIcon(props: SvgIconProps): React.ReactElement {
  return <SvgFillNoneIcon {...props} component={PlusCircle} data-qa="PlusCircleIcon" />;
}
export function PlusIcon(props: SvgIconProps): React.ReactElement {
  return <SvgFillNoneIcon {...props} component={Plus} data-qa="PlusIcon" />;
}
export function PlusThickIcon(props: SvgIconProps): React.ReactElement {
  return <SvgFillNoneIcon {...props} component={PlusThick} data-qa="PlusThickIcon" />;
}
export function ProfileIcon(props: SvgIconProps): React.ReactElement {
  return <SvgFillNoneIcon {...props} component={Profile} data-qa="ProfileIcon" />;
}
export function RadioIcon(props: SvgIconProps): React.ReactElement {
  return <SvgIcon {...props} component={Radio} data-qa="RadioIcon" />;
}
export function ReceiveIcon(props: SvgIconProps): React.ReactElement {
  return <SvgFillNoneIcon {...props} component={Receive} data-qa="ReceiveIcon" />;
}
export function WalletReceiveIcon(props: SvgIconProps): React.ReactElement {
  return <SvgFillNoneIcon {...props} component={WalletReceive} data-qa="ReceiveIcon" />;
}
export function RewardsIcon(props: SvgIconProps): React.ReactElement {
  return <SvgFillNoneIcon {...props} component={Rewards} data-qa="RewardsIcon" />;
}
export function ScanIcon(props: SvgIconProps): React.ReactElement {
  return <SvgFillNoneIcon {...props} component={Scan} data-qa="ScanIcon" />;
}
export function SearchIcon(props: SvgIconProps): React.ReactElement {
  return <SvgFillNoneIcon {...props} component={Search} data-qa="SearchIcon" />;
}
export function ShareIcon(props: SvgIconProps): React.ReactElement {
  return <SvgFillNoneIcon {...props} component={Share} data-qa="ShareIcon" />;
}
export function ShoppingCartIcon(props: SvgIconProps): React.ReactElement {
  return <SvgFillNoneIcon {...props} component={ShoppingCart} data-qa="ShoppingCart" />;
}
export function ShowIcon(props: SvgIconProps): React.ReactElement {
  return <SvgFillNoneIcon {...props} component={Show} data-qa="ShowIcon" />;
}
export function SofortIcon(props: SvgIconProps): React.ReactElement {
  return <SvgFillNoneIcon {...props} component={Sofort} data-qa="SofortIcon" />;
}
export function SupportIcon(props: SvgIconProps): React.ReactElement {
  return <SvgFillNoneIcon {...props} component={Support} data-qa="SupportIcon" />;
}
export function SymbolIcon(props: SvgIconProps): React.ReactElement {
  return <SvgFillNoneIcon {...props} component={Symbol} data-qa="SymbolIcon" />;
}
export function SwitchArrowsIcon(props: SvgIconProps): React.ReactElement {
  return <SvgFillNoneIcon {...props} component={SwitchArrows} data-qa="SwitchArrowsIcon" />;
}
export function Time1Icon(props: SvgIconProps): React.ReactElement {
  return <SvgFillNoneIcon {...props} component={Time1} data-qa="Time1Icon" />;
}
export function TimeCircleIcon(props: SvgIconProps): React.ReactElement {
  return <SvgFillNoneIcon {...props} component={TimeCircle} data-qa="TimeCircleIcon" />;
}
export function TimeIcon(props: SvgIconProps): React.ReactElement {
  return <SvgFillNoneIcon {...props} component={Time} data-qa="TimeIcon" />;
}
export function TransactionsIcon(props: SvgIconProps): React.ReactElement {
  return <SvgFillNoneIcon {...props} component={Transactions} data-qa="TransactionsIcon" />;
}
export function UnitedKingdomIcon(props: SvgIconProps): React.ReactElement {
  return <SvgFillNoneIcon {...props} component={UnitedKingdom} data-qa="UnitedKingdomIcon" />;
}
export function UnitedStatesIcon(props: SvgIconProps): React.ReactElement {
  return <SvgFillNoneIcon {...props} component={UnitedStates} data-qa="UnitedStatesIcon" />;
}
export function UsdcoinIcon(props: SvgIconProps): React.ReactElement {
  return <SvgFillNoneIcon {...props} component={Usdcoin} data-qa="UsdcoinIcon" />;
}
export function UsdtIcon(props: SvgIconProps): React.ReactElement {
  return <SvgFillNoneIcon {...props} component={Usdt} data-qa="UsdtIcon" />;
}
export function WalletActiveIcon(props: SvgIconProps): React.ReactElement {
  return <SvgFillNoneIcon {...props} component={WalletActive} data-qa="WalletActiveIcon" />;
}
export function WalletCircleIcon(props: SvgIconProps): React.ReactElement {
  return <SvgFillNoneIcon {...props} component={WalletCircle} data-qa="WalletCircleIcon" />;
}
export function WalletNormalIcon(props: SvgIconProps): React.ReactElement {
  return <SvgFillNoneIcon {...props} component={WalletNormal} data-qa="WalletNormalIcon" />;
}
export function WalletIcon(props: SvgIconProps): React.ReactElement {
  return <SvgFillNoneIcon {...props} component={Wallet} data-qa="WalletIcon" />;
}
export function WarningIcon(props: SvgIconProps): React.ReactElement {
  return <SvgFillNoneIcon {...props} component={Warning} data-qa="WarningIcon" />;
}
export function WithdrawCircleIcon(props: SvgIconProps): React.ReactElement {
  return <SvgFillNoneIcon {...props} component={WithdrawCircle} data-qa="WithdrawCircleIcon" />;
}
export function TelegramIcon(props: SvgIconProps): React.ReactElement {
  return <SvgIcon {...props} component={Telegram} data-qa="TelegramIcon" />;
}
export function TelegramSquareIcon(props: SvgIconProps): React.ReactElement {
  return <SvgIcon {...props} component={TelegramSquare} data-qa="TelegramSquareIcon" />;
}
export function TwitterIcon(props: SvgIconProps): React.ReactElement {
  return <SvgIcon {...props} component={Twitter} data-qa="TwitterIcon" />;
}
export function VisaIcon(props: SvgIconProps): React.ReactElement {
  return <SvgIcon {...props} component={Visa} data-qa="VisaIcon" />;
}
export function WhatsappSquareIcon(props: SvgIconProps): React.ReactElement {
  return <SvgIcon {...props} component={WhatsappSquare} data-qa="WhatsappSquareIcon" />;
}
export function FacebookSquareIcon(props: SvgIconProps): React.ReactElement {
  return <SvgIcon {...props} component={FacebookSquare} data-qa="FacebookSquareIcon" />;
}
export function EllipsisMenuIcon(props: SvgIconProps): React.ReactElement {
  return <SvgIcon {...props} component={EllipsisMenu} data-qa="EllipsisMenuIcon" />;
}
export function EnlargeIcon(props: SvgIconProps): React.ReactElement {
  return <SvgIcon {...props} component={Enlarge} data-qa="EnlargeIco" />;
}
export function CopySquareIcon(props: SvgIconProps): React.ReactElement {
  return <SvgIcon {...props} component={CopySquare} data-qa="CopySquareIcon" />;
}
export function CreditCardIcon(props: SvgIconProps): React.ReactElement {
  return <SvgIcon {...props} component={CreditCard} data-qa="CreditCardIcon" />;
}
export function StarBadgeIcon(props: SvgIconProps): React.ReactElement {
  return <SvgIcon {...props} component={StarBadge} data-qa="StarBadgeIcon" />;
}
export function OutlinedCircleCloseIcon(props: SvgIconProps): React.ReactElement {
  return <SvgIcon {...props} component={OutlinedCircleClose} data-qa="OutlinedCircleCloseIcon" />;
}
export function ArrowRightThickVariantIcon(props: SvgIconProps): React.ReactElement {
  return (
    <SvgIcon {...props} component={ArrowRightThickVariant} data-qa="ArrowRightThickVariantIcon" />
  );
}
export function CheckIcon(props: SvgIconProps): React.ReactElement {
  return <SvgIcon {...props} component={Check} data-qa="CheckIcon" />;
}
export function SendIcon(props: SvgIconProps): React.ReactElement {
  return <SvgIcon {...props} component={Send} data-qa="SendIcon" />;
}
export function StopwatchIcon(props: SvgIconProps): React.ReactElement {
  return <SvgIcon {...props} component={Stopwatch} data-qa="StopwatchIcon" />;
}
export function SquareInfoIcon(props: SvgIconProps): React.ReactElement {
  return <SvgIcon {...props} component={SquareInfo} data-qa="SquareInfoIcon" />;
}
export function SquareWarningIcon(props: SvgIconProps): React.ReactElement {
  return <SvgIcon {...props} component={SquareWarning} data-qa="SquareWarningIcon" />;
}
export function SquareTickIcon(props: SvgIconProps): React.ReactElement {
  return <SvgIcon {...props} component={SquareTick} data-qa="SquareTickIcon" />;
}
export function GoogleIcon(props: SvgIconProps): React.ReactElement {
  return <SvgIcon {...props} component={Google} data-qa="GoogleIcon" />;
}
export function AppleIcon(props: SvgIconProps): React.ReactElement {
  return <SvgIcon {...props} component={Apple} data-qa="AppleIcon" />;
}
export function LinkIcon(props: SvgIconProps): React.ReactElement {
  return <SvgIcon {...props} component={Link} data-qa="LinkIcon" />;
}
export function FingerprintIcon(props: SvgIconProps): React.ReactElement {
  return <SvgIcon {...props} component={Fingerprint} data-qa="FingerprintIcon" />;
}
export function BankIcon(props: SvgIconProps): React.ReactElement {
  return <SvgIcon {...props} component={Bank} data-qa="BankIcon" />;
}
export function WhatsappIcon(props: SvgIconProps): React.ReactElement {
  return <SvgIcon {...props} component={Whatsapp} data-qa="WhatsappIcon" />;
}
export function NotificationIcon(props: SvgIconProps): React.ReactElement {
  return <SvgFillNoneIcon {...props} component={Notification} data-qa="NotificationIcon" />;
}
export function PlusCircleThickIcon(props: SvgIconProps): React.ReactElement {
  return <SvgIcon {...props} component={PlusCircleThick} data-qa="PlusCircleThickIcon" />;
}
export function SellIcon(props: SvgIconProps): React.ReactElement {
  return <SvgIcon {...props} component={Sell} data-qa="SellIcon" />;
}
export function WithdrawIcon(props: SvgIconProps): React.ReactElement {
  return <SvgIcon {...props} component={ArrowUpRightCircleThick} data-qa="WithdrawIcon" />;
}
export function DepositIcon({ sx, ...props }: SvgIconProps): React.ReactElement {
  return (
    <SvgIcon
      {...props}
      component={ArrowUpRightCircleThick}
      data-qa="DepositIcon"
      sx={{ transform: 'rotate(90deg)', ...sx }}
    />
  );
}
export function FailIcon(props: SvgIconProps): React.ReactElement {
  return <SvgIcon {...props} component={CloseCircleThick} data-qa="FailIcon" />;
}
export function PendingIcon(props: SvgIconProps): React.ReactElement {
  return <SvgIcon {...props} component={Pending} data-qa="PendingIcon" />;
}
export function AccountLimitsIcon(props: SvgIconProps): React.ReactElement {
  return <SvgIcon {...props} component={AccountLimits} data-qa="AccountLimitsIcon" />;
}
export function EthereumIcon(props: SvgIconProps): React.ReactElement {
  return <SvgFillNoneIcon {...props} component={Ethereum} data-qa="EthereumIcon" />;
}
export function ReferralIcon(props: SvgIconProps): React.ReactElement {
  return <SvgIcon {...props} component={Referral} data-qa="ReferralIcon" />;
}
export function NoahCircleIcon(props: SvgIconProps): React.ReactElement {
  return <SvgIcon {...props} component={NoahCircle} data-qa="NoahCircleIcon" />;
}
export function BellCircleIcon(props: SvgIconProps): React.ReactElement {
  return <SvgIcon {...props} component={BellCircle} data-qa="BellCircleIcon" />;
}
export function PolygonIcon(props: SvgIconProps): React.ReactElement {
  return <SvgIcon {...props} component={Polygon} data-qa="PolygonIcon" />;
}
export function TronIcon(props: SvgIconProps): React.ReactElement {
  return <SvgIcon {...props} component={Tron} data-qa="TronIcon" />;
}
