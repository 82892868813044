import { SVGProps } from 'react';
const SvgDocument = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 25"
    width="1em"
    height="1em"
    {...props}
  >
    <path
      fill="currentColor"
      d="M17.75 2.115a2.25 2.25 0 0 1 2.245 2.096l.005.154v15.498a2.25 2.25 0 0 1-2.096 2.244l-.154.005H6.25a2.25 2.25 0 0 1-2.245-2.096L4 19.863V4.365A2.25 2.25 0 0 1 6.096 2.12l.154-.005h11.5Zm-10 4.992a.75.75 0 1 0 0 1.5h8.5a.75.75 0 1 0 0-1.5h-8.5ZM7 11.857c0 .414.336.75.75.75h8.5a.75.75 0 0 0 0-1.5h-8.5a.75.75 0 0 0-.75.75Zm.75 3.25a.75.75 0 1 0 0 1.5h8.5a.75.75 0 0 0 0-1.5h-8.5Z"
    />
  </svg>
);
export default SvgDocument;
