import { SVGProps } from 'react';
const SvgShare = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g clipPath="url(#share_svg__a)">
      <path
        fill="currentColor"
        d="M18 15.893a2.79 2.79 0 0 0-1.878.738l-6.833-3.977a3.13 3.13 0 0 0 .086-.671c0-.23-.038-.45-.086-.671l6.756-3.939c.517.48 1.198.777 1.955.777a2.871 2.871 0 0 0 2.875-2.875A2.871 2.871 0 0 0 18 2.4a2.871 2.871 0 0 0-2.875 2.875c0 .23.038.45.086.67l-6.756 3.94A2.863 2.863 0 0 0 6.5 9.107a2.871 2.871 0 0 0-2.875 2.875A2.871 2.871 0 0 0 6.5 14.858c.757 0 1.438-.297 1.955-.776l6.823 3.986a2.704 2.704 0 0 0-.076.623A2.802 2.802 0 0 0 18 21.49a2.802 2.802 0 0 0 2.798-2.799A2.802 2.802 0 0 0 18 15.893Zm0-11.577a.96.96 0 0 1 .958.959.961.961 0 0 1-.958.958.961.961 0 0 1-.958-.958.96.96 0 0 1 .958-.959ZM6.5 12.941a.961.961 0 0 1-.958-.958.96.96 0 0 1 .958-.959c.527 0 .958.432.958.959a.961.961 0 0 1-.958.958ZM18 19.67a.961.961 0 0 1-.958-.959.96.96 0 0 1 .958-.958.96.96 0 0 1 .958.958.961.961 0 0 1-.958.959Z"
      />
    </g>
    <defs>
      <clipPath id="share_svg__a">
        <path fill="#fff" d="M0 0h23v23H0z" transform="translate(.75 .483)" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgShare;
