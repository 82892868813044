import { SVGProps } from 'react';
const SvgCloseCircle = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    fillRule="evenodd"
    strokeLinejoin="round"
    strokeMiterlimit={2}
    clipRule="evenodd"
    viewBox="0 0 24 25"
    width="1em"
    height="1em"
    {...props}
  >
    <path
      fill="currentColor"
      d="M12.207 2.321c5.519 0 10 4.481 10 10 0 5.52-4.481 10-10 10s-10-4.48-10-10c0-5.519 4.481-10 10-10Zm1.36 10.2 3.2-3.2a.819.819 0 0 0 0-1.159.82.82 0 0 0-1.159-.001l-3.201 3.201-3.2-3.201a.817.817 0 0 0-1.159.001.817.817 0 0 0-.241.579c0 .218.087.426.24.58l3.201 3.2-3.201 3.2a.82.82 0 0 0-.177.266.808.808 0 0 0-.001.628.823.823 0 0 0 1.072.444c.1-.042.19-.102.266-.178l3.2-3.2 3.2 3.2a.82.82 0 0 0 1.16-1.16l-3.2-3.2Z"
    />
  </svg>
);
export default SvgCloseCircle;
