import { SVGProps } from 'react';
const SvgContact = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <circle cx={12.123} cy={12.04} r={10.623} fill="#E3E3E5" />
    <mask
      id="contact_svg__a"
      width={21}
      height={17}
      x={2}
      y={6}
      maskUnits="userSpaceOnUse"
      style={{
        maskType: 'alpha',
      }}
    >
      <path fill="#fff" d="M2.086 16.171h20.065v6.492H2.086z" />
      <ellipse cx={12.076} cy={11.998} fill="#fff" rx={5.27} ry={5.269} />
    </mask>
    <g mask="url(#contact_svg__a)">
      <circle cx={12.119} cy={12.042} r={10.623} fill="currentColor" />
    </g>
  </svg>
);
export default SvgContact;
