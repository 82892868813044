import { SVGProps } from 'react';
const SvgCheckbox = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <rect width={23} height={23} x={0.5} y={0.5} fill="currentColor" rx={7.5} />
    <rect width={23} height={23} x={0.5} y={0.5} stroke="#8A8A8A" rx={7.5} />
  </svg>
);
export default SvgCheckbox;
