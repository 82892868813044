import type { ReactElement, ReactNode } from 'react';
import { useTheme } from '@mui/material/styles';
import { Feature } from '@noah-labs/noah-schema';
import { NovuProvider as BaseNovuProvider } from '@novu/notification-center';
import { useUserInit } from '../../user/data/useUserInit';
import { useIsFeatureEnabled } from '../../user/hooks/useIsFeatureEnabled';
import { useNovuSubscriberHashQuery } from '../data/notifications.generated';

type NovuProviderProps = {
  applicationId: string;
  children: ReactNode;
};

export function NovuProvider({ applicationId, children }: NovuProviderProps): ReactElement {
  const theme = useTheme();
  const { data } = useUserInit();
  const isNotificationsFeatureEnabled = useIsFeatureEnabled(Feature.Notifications);

  const { data: subscriberHashQuery } = useNovuSubscriberHashQuery(undefined, {
    enabled: isNotificationsFeatureEnabled && !!data?.userProfile.UserID,
  });

  const subscriberHash = subscriberHashQuery?.novuSubscriberHash.SubscriberHash;

  return (
    <BaseNovuProvider
      applicationIdentifier={subscriberHash ? applicationId : ''}
      styles={{
        header: {
          root: {
            backgroundColor: theme.palette.grayscale.offWhite,
          },
        },
      }}
      subscriberHash={subscriberHash}
      subscriberId={data?.userProfile.UserID}
    >
      {children}
    </BaseNovuProvider>
  );
}
