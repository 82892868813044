import { SVGProps } from 'react';
const SvgBellCircle = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <path
      fill="currentColor"
      d="M24 12c0 6.627-5.373 12-12 12S0 18.627 0 12 5.373.001 12 .001s12 5.372 12 12Z"
    />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M12 21.6a9.6 9.6 0 1 0-9.6-9.599c0 5.3 4.298 9.599 9.6 9.599Zm0 2.4c6.627 0 12-5.373 12-12S18.627.001 12 .001s-12 5.372-12 12C0 18.626 5.373 24 12 24Z"
      clipRule="evenodd"
    />
    <path
      fill="#fff"
      d="m16.77 13.12-.65-1.079a2.416 2.416 0 0 1-.26-.962V9.434a3.843 3.843 0 0 0-2.191-3.464A1.902 1.902 0 0 0 11.992 5c-.709 0-1.346.384-1.684.988a3.854 3.854 0 0 0-2.145 3.445v1.645c0 .266-.123.721-.26.956l-.656 1.085c-.26.436-.319.917-.156 1.359.156.435.526.773 1.007.936 1.261.429 2.587.637 3.913.637 1.327 0 2.653-.208 3.914-.63.455-.15.806-.495.975-.943a1.545 1.545 0 0 0-.13-1.359Zm-2.932 3.588a1.96 1.96 0 0 1-1.84 1.294c-.513 0-1.02-.208-1.378-.579a1.795 1.795 0 0 1-.455-.721c.085.013.17.02.26.032a12.898 12.898 0 0 0 1.586.104c.37 0 .741-.02 1.105-.052.137-.013.274-.02.404-.039l.318-.039Z"
    />
  </svg>
);
export default SvgBellCircle;
