import { SVGProps } from 'react';
const SvgPending = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <path
      fill="currentColor"
      stroke="#fff"
      strokeWidth={2.4}
      d="M22.8 12.001C22.8 17.965 17.965 22.8 12 22.8c-5.965 0-10.8-4.835-10.8-10.799 0-5.964 4.835-10.8 10.8-10.8 5.965 0 10.8 4.836 10.8 10.8Z"
    />
    <path fill="currentColor" fillRule="evenodd" d="m16 15.335-4-2.958V6.668" clipRule="evenodd" />
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2.4}
      d="m16 15.335-4-2.958V6.668"
    />
  </svg>
);
export default SvgPending;
