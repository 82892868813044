import { SVGProps } from 'react';
const SvgCheckedRadio = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <path fill="currentColor" d="M19.2 12a7.2 7.2 0 1 0-14.4 0 7.2 7.2 0 0 0 14.4 0Z" />
    <path
      stroke="currentColor"
      strokeWidth={0.6}
      d="M18.9 12a6.9 6.9 0 1 0-13.8 0 6.9 6.9 0 0 0 13.8 0Z"
    />
    <path
      fill="currentColor"
      stroke="#F5F5F6"
      strokeWidth={1.309}
      d="M17.345 12a5.345 5.345 0 1 0-10.69 0 5.345 5.345 0 0 0 10.69 0Z"
    />
  </svg>
);
export default SvgCheckedRadio;
