import { AuthGroups } from '@noah-labs/noah-schema';
import { createRoute } from './utils/createRoute';

const base = 'auth';
const guestGroups = new Set([AuthGroups.guest]);
const personalGroups = new Set([AuthGroups.personal_basic]);

export const routes = {
  base: {
    groups: guestGroups,
    path: `/${base}`,
  },
  error: createRoute({
    base,
    groups: guestGroups,
    segments: ['error'],
    title: 'Something went wrong',
  }),
  recovery: createRoute({
    base,
    groups: guestGroups,
    segments: ['recovery'],
    title: 'Forgotten password',
  }),
  signedOut: createRoute({
    base,
    groups: guestGroups,
    segments: ['signedout'],
    title: 'Signed Out',
  }),
  signIn: createRoute({
    base,
    groups: guestGroups,
    segments: ['signin'],
    title: 'Sign In',
  }),
  signOut: createRoute({
    base,
    segments: ['signout'],
    title: 'Sign Out',
  }),
  signUp: createRoute({
    base,
    groups: guestGroups,
    segments: ['signup'],
    title: 'Sign Up',
  }),
  verify: createRoute({
    base,
    groups: personalGroups,
    segments: ['verify'],
    title: 'Verify Email',
  }),
};
