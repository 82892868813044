import { SVGProps } from 'react';
const SvgOpenInWindow = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 25"
    width="1em"
    height="1em"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M2 3.707a1.6 1.6 0 0 1 1.6-1.6h16.8a1.6 1.6 0 0 1 1.6 1.6v16.8a1.6 1.6 0 0 1-1.6 1.6H8a1 1 0 1 1 0-2h12v-16H4v12a1 1 0 1 1-2 0v-12.4Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M7 12.107a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v4a1 1 0 1 1-2 0v-1.585l-6.793 6.792A1 1 0 1 1 2.793 19.9l6.793-6.793H8a1 1 0 0 1-1-1Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgOpenInWindow;
