import { SVGProps } from 'react';
const SvgReferral = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <path
      fill="currentColor"
      d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10Z"
    />
    <path
      fill="#fff"
      d="M14.018 5.072a2.019 2.019 0 1 0 .001 4.037 2.019 2.019 0 0 0-.001-4.037ZM9.982 8.1a2.019 2.019 0 1 0 .001 4.037A2.019 2.019 0 0 0 9.982 8.1ZM12 13.148H7.962a2.019 2.019 0 0 0-2.018 2.018v2.017h8.074v-2.018A2.019 2.019 0 0 0 12 13.149Zm4.038-3.03H13.01v2.2c.856.304 1.538.98 1.842 1.836h3.204v-2.018a2.019 2.019 0 0 0-2.018-2.018Z"
    />
  </svg>
);
export default SvgReferral;
