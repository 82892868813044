import { SVGProps } from 'react';
const SvgDiscord = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="1em" height="1em" {...props}>
    <path
      fill="currentColor"
      d="M19.624 4.518a18.142 18.142 0 0 0-4.478-1.39.068.068 0 0 0-.072.035c-.193.344-.408.792-.558 1.145a16.749 16.749 0 0 0-5.03 0 11.59 11.59 0 0 0-.565-1.145.07.07 0 0 0-.072-.034c-1.572.27-3.075.744-4.478 1.389a.064.064 0 0 0-.03.025C1.49 8.803.708 12.96 1.091 17.065c.002.02.013.04.029.051a18.244 18.244 0 0 0 5.493 2.777.071.071 0 0 0 .077-.025c.423-.578.8-1.187 1.124-1.828a.07.07 0 0 0-.038-.097 12.014 12.014 0 0 1-1.716-.818.07.07 0 0 1-.007-.117 9.4 9.4 0 0 0 .34-.267.068.068 0 0 1 .072-.01c3.6 1.644 7.498 1.644 11.056 0a.068.068 0 0 1 .072.01c.11.09.226.18.342.267a.07.07 0 0 1-.006.117c-.548.32-1.118.591-1.717.817a.07.07 0 0 0-.038.098c.33.64.708 1.25 1.123 1.827a.07.07 0 0 0 .078.026 18.183 18.183 0 0 0 5.502-2.777.07.07 0 0 0 .028-.05c.459-4.746-.768-8.868-3.252-12.522a.056.056 0 0 0-.03-.026zM8.352 14.566c-1.084 0-1.977-.996-1.977-2.218s.875-2.217 1.977-2.217c1.11 0 1.994 1.004 1.977 2.217 0 1.222-.876 2.218-1.977 2.218zm7.31 0c-1.084 0-1.977-.996-1.977-2.218s.876-2.217 1.977-2.217c1.11 0 1.994 1.004 1.977 2.217 0 1.222-.867 2.218-1.977 2.218z"
    />
  </svg>
);
export default SvgDiscord;
