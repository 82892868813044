import { SVGProps } from 'react';
const SvgStopwatch = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    data-name="Layer 1"
    viewBox="0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <circle cx={12.03} cy={13.15} r={9.49} fill="none" stroke="currentColor" strokeWidth={1.69} />
    <path
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeWidth={0.83}
      d="M11.83 3.09V1.24"
    />
    <path
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeWidth={1.24}
      d="M12.82 1.01h-1.57"
    />
    <path
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeWidth={1.69}
      d="M12.98 13.91V9.7m-1.47 4.21H8.35"
    />
  </svg>
);
export default SvgStopwatch;
