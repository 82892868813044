import { SVGProps } from 'react';
const SvgRadio = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <rect width={13.8} height={13.8} x={5.1} y={5.1} fill="currentColor" rx={6.9} />
    <rect width={13.8} height={13.8} x={5.1} y={5.1} stroke="#8A8A8A" strokeWidth={0.6} rx={6.9} />
  </svg>
);
export default SvgRadio;
