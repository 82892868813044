import { SVGProps } from 'react';
const SvgCloseCircleThick = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <path
      fill="currentColor"
      stroke="#fff"
      strokeWidth={2.4}
      d="M22.8 12c0 5.965-4.835 10.8-10.8 10.8-5.965 0-10.8-4.835-10.8-10.8C1.2 6.036 6.035 1.201 12 1.201c5.965 0 10.8 4.835 10.8 10.8Z"
    />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M9.2 7.237a1.2 1.2 0 1 0-1.697 1.697l3.067 3.067-3.065 3.065a1.2 1.2 0 0 0 1.697 1.697l3.065-3.065 3.065 3.065a1.2 1.2 0 1 0 1.697-1.697l-3.065-3.065 3.066-3.067a1.2 1.2 0 1 0-1.697-1.697l-3.066 3.067L9.2 7.237Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgCloseCircleThick;
