import { SVGProps } from 'react';
const SvgEthereum = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <path
      fill="#627EEA"
      d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10Z"
    />
    <path fill="#fff" fillOpacity={0.602} d="M12.311 4.5v5.544l4.686 2.093L12.31 4.5Z" />
    <path fill="#fff" d="m12.311 4.5-4.686 7.637 4.686-2.093V4.5Z" />
    <path fill="#fff" fillOpacity={0.602} d="M12.311 15.73v3.767L17 13.01l-4.689 2.72Z" />
    <path fill="#fff" d="M12.311 19.497v-3.768L7.625 13.01l4.686 6.487Z" />
    <path fill="#fff" fillOpacity={0.2} d="m12.311 14.858 4.686-2.72-4.686-2.093v4.813Z" />
    <path fill="#fff" fillOpacity={0.602} d="m7.625 12.137 4.686 2.721v-4.813l-4.686 2.092Z" />
  </svg>
);
export default SvgEthereum;
