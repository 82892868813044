import { SVGProps } from 'react';
const SvgSquareWarning = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <path d="M16.9 0H7.1C3.2 0 0 3.2 0 7.1v9.7c0 4 3.2 7.2 7.1 7.2h9.7c3.9 0 7.1-3.2 7.1-7.1V7.1c.1-3.9-3.1-7.1-7-7.1z" />
    <path
      fill="#fff"
      stroke="#fff"
      strokeWidth={2.375}
      d="M10.4 5.3c.7-1.3 2.6-1.3 3.3 0l5.8 10.5c.7 1.2-.2 2.7-1.6 2.7H6.2c-1.4 0-2.3-1.5-1.6-2.7l5.8-10.5z"
    />
    <path d="M12.1 6.5c.7 0 1.2.5 1.2 1.1V12c0 .6-.5 1.1-1.2 1.1s-1.2-.5-1.2-1.1V7.7c0-.7.5-1.2 1.2-1.2zm-.8 8c.5-.5 1.2-.5 1.7 0s.5 1.2 0 1.7-1.2.5-1.7 0-.5-1.2 0-1.7z" />
  </svg>
);
export default SvgSquareWarning;
