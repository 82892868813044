import { SVGProps } from 'react';
const SvgSwitchArrows = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <path
      fill="currentColor"
      stroke="currentColor"
      strokeWidth={0.5}
      d="m10.163 7.51.97.971 3.851-3.843v8.382h1.378V4.638l3.843 3.85.978-.978L15.673 2l-5.51 5.51Zm3.672 8.98-.971-.971-3.85 3.843V10.98H7.636v8.382l-3.843-3.85-.978.978L8.325 22l5.51-5.51Z"
    />
  </svg>
);
export default SvgSwitchArrows;
