import { SVGProps } from 'react';
const SvgPolygon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <path
      fill="url(#polygon_svg__a)"
      d="M12 22c5.542 0 10-4.458 10-10S17.542 2 12 2 2 6.458 2 12s4.458 10 10 10Z"
    />
    <path
      fill="#fff"
      d="m15.394 14.451 3.321-1.856a.55.55 0 0 0 .285-.477V8.407a.55.55 0 0 0-.285-.478l-3.32-1.855a.588.588 0 0 0-.57 0l-3.321 1.855a.55.55 0 0 0-.285.478v6.632l-2.329 1.3-2.329-1.3v-2.602l2.33-1.301 1.535.858V10.25l-1.25-.7a.589.589 0 0 0-.57 0l-3.321 1.856a.55.55 0 0 0-.285.478v3.71a.55.55 0 0 0 .285.478l3.32 1.856a.59.59 0 0 0 .57 0l3.32-1.856a.55.55 0 0 0 .286-.477V8.96l.041-.023L15.11 7.66l2.329 1.3v2.603l-2.33 1.3-1.533-.856v1.745l1.249.698a.59.59 0 0 0 .57 0v.001Z"
    />
    <defs>
      <linearGradient
        id="polygon_svg__a"
        x1={22}
        x2={2.5}
        y1={11.5}
        y2={11.5}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#7C40E5" />
        <stop offset={1} stopColor="#A627C3" />
      </linearGradient>
    </defs>
  </svg>
);
export default SvgPolygon;
