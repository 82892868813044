/* eslint-disable react/no-multi-comp */
import type { ReactElement } from 'react';
import { StrictMode, useEffect } from 'react';
import { CssBaseline, GlobalStyles } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { api, checkoutApi, moonpayApi } from '@noah-labs/core-services';
import { DialogsProvider } from '@noah-labs/core-web-ui/src/dialogs/DialogsProvider';
import { NavigationProvider } from '@noah-labs/core-web-ui/src/hooks/useNavigation';
import { useSearchParams } from '@noah-labs/core-web-ui/src/hooks/useSearchParams';
import type { PpWC } from '@noah-labs/core-web-ui/src/types';
import { ErrorBoundary } from '@noah-labs/core-web-ui/src/utility/ErrorBoundary';
import { OryProvider } from '@noah-labs/fe-shared-data-access-auth';
import { logger } from '@noah-labs/shared-logger/src/browser/logger';
import { isBrowser } from '@noah-labs/shared-tools/src/browser/utils';
import type { WrapRootElementBrowserArgs, WrapRootElementNodeArgs } from 'gatsby';
import { SnackbarProvider } from 'notistack';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter, StaticRouter, useHistory } from 'react-router-dom';
// import { reportWebVitals } from './reportWebVitals';
import { Seo } from './components/layout';
import { AnalyticsProvider } from './modules/analytics';
import { getAppType } from './modules/auth/utils';
import { DatadogProvider } from './modules/datadog/context/DatadogProvider';
import { LiveChatProvider } from './modules/help';
import { NovuProvider } from './modules/notifications/providers/NovuProvider';
import { SardineProvider } from './modules/sardine';
import { SigningProvider } from './modules/signing/context';
import { SubscriptionProvider } from './modules/subscriptions/hooks/SubscriptionProvider';
import { routes as walletRoutes } from './modules/wallet/routes';
import { globalStyles, lightTheme } from './theme';
import { snackbarProviderConfig, webConfigBrowser } from './webConfigBrowser';
import './polyfills';

// see: node_modules/react-query/types/core/types.d.ts and https://react-query.tanstack.com/guides/important-defaults#_top
const queryClient = new QueryClient({
  defaultOptions: {
    mutations: {
      retry: false,
    },
    queries: {
      retry: false,
      staleTime: 10000,
    },
  },
});

function BaseRootProviders({ children }: PpWC): ReactElement {
  const { analytics, auth, sardine, segment, settings, url } = webConfigBrowser;
  const history = useHistory();

  /**
   * pass the referral code parameter to the Ory provider so it will be added to the Ory urls
   * using useSearchParams causes a re-render here when `updateExitTo` is called because it creates a new state in LSM
   * TODO: give useSearchParams its own context
   */
  const searchParams = useSearchParams();
  const referralCode = searchParams?.get(settings.referralCodeParam);
  let authSp;
  if (referralCode) {
    authSp = {
      [settings.referralCodeParam]: referralCode,
    };
  }

  useEffect(() => {
    const appType = getAppType();
    logger.info('app type:', appType);
  }, []);

  /**
   * In general, lots of components and some hooks / providers need access to the dialogs and snackbars, hence, we should mount those first
   */
  return (
    <SnackbarProvider {...snackbarProviderConfig}>
      <DialogsProvider history={history}>
        <OryProvider appUrl={url} oryCustomUrl={auth.oryCustomUrl} searchParams={authSp}>
          <QueryClientProvider client={queryClient}>
            <SubscriptionProvider>
              <LiveChatProvider>
                <SardineProvider
                  clientId={sardine.clientId}
                  environment={sardine.environment}
                  scriptUrl={sardine.scriptUrl}
                >
                  <SigningProvider>
                    <AnalyticsProvider
                      apiHost={analytics.apiHost}
                      cdnUrl={analytics.cdnUrl}
                      writeKey={segment.writeKey}
                    >
                      <NovuProvider applicationId={webConfigBrowser.novu.applicationId}>
                        <DatadogProvider>{children}</DatadogProvider>
                      </NovuProvider>
                    </AnalyticsProvider>
                  </SigningProvider>
                </SardineProvider>
              </LiveChatProvider>
            </SubscriptionProvider>
          </QueryClientProvider>
        </OryProvider>
      </DialogsProvider>
    </SnackbarProvider>
  );
}

/**
 * ErrorBoundary needs ThemeProvider but otherwise should be uppermost in the tree
 */
function RootProviders({ children }: PpWC): ReactElement {
  logger.highlight(`Build: ${webConfigBrowser.commitHash}`);
  /**
   * Set the ?search string in initialState of NavigationProvider so that they are persisted after first load
   * Use window.location.search instead of useLocation to prevent rerenders - we only care about the first load here
   */
  const search = isBrowser ? window.location.search : '';

  /**
   * Configure APIs, shouldn't be run more than once but put in useEffect just in case
   */
  useEffect(() => {
    api.configure(webConfigBrowser.graphql);
    moonpayApi.configure(webConfigBrowser.moonpay);
    checkoutApi.configure(webConfigBrowser.checkout.url, webConfigBrowser.checkout.publicKey);
  }, []);

  return (
    <StrictMode>
      <NavigationProvider initialExitTo={walletRoutes.base.path} initialSearch={search}>
        <ThemeProvider theme={lightTheme}>
          <ErrorBoundary>
            <BaseRootProviders>
              {children}
              <Seo />
              <CssBaseline />
              <GlobalStyles styles={globalStyles} />
              <ReactQueryDevtools initialIsOpen={false} />
            </BaseRootProviders>
          </ErrorBoundary>
        </ThemeProvider>
      </NavigationProvider>
    </StrictMode>
  );
}

/**
 * Note that React Hooks can not be used in either RootProvidersBrowser or RootProvidersSSR
 */
export function RootProvidersBrowser({ element }: WrapRootElementBrowserArgs): ReactElement {
  return (
    <BrowserRouter>
      <RootProviders>{element}</RootProviders>
    </BrowserRouter>
  );
}

export function RootProvidersSSR({ element }: WrapRootElementNodeArgs): ReactElement {
  return (
    <StaticRouter>
      <RootProviders>{element}</RootProviders>
    </StaticRouter>
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
