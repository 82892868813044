import { assetsHost } from '@noah-labs/cloud-shared-constants';

const baseUrl = `https://${assetsHost}`;

export const assetPaths = {
  baseUrl,
  fonts: `${baseUrl}/fonts`,
  host: assetsHost,
  images: `${baseUrl}/images`,
};
