import { SVGProps } from 'react';
const SvgBank = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <path
      fill="currentColor"
      d="M21.667 20.52h-.6a.304.304 0 0 1-.311-.311v-.6a.342.342 0 0 0-.334-.334h-1.978a.2.2 0 0 1-.2-.2v-7.11a.2.2 0 0 1 .2-.2h1.89a.616.616 0 0 0 .4-1.09L12.4 3.386a.638.638 0 0 0-.822 0l-8.334 7.29c-.444.377-.155 1.088.4 1.088h1.912a.2.2 0 0 1 .2.2v7.111a.2.2 0 0 1-.2.2h-2a.304.304 0 0 0-.311.311v.645a.294.294 0 0 1-.29.288h-.644a.304.304 0 0 0-.311.312v.622c0 .178.133.311.311.311H21.69a.304.304 0 0 0 .311-.311v-.6c0-.2-.156-.333-.333-.333Zm-10.911-1.445a.2.2 0 0 1-.2.2H8.444a.2.2 0 0 1-.2-.2v-7.11a.2.2 0 0 1 .2-.2h2.111a.2.2 0 0 1 .2.2v7.11Zm5 0a.2.2 0 0 1-.2.2h-2.111a.2.2 0 0 1-.2-.2v-7.11a.2.2 0 0 1 .2-.2l2.11-.001c.112 0 .2.089.2.2v7.111Z"
    />
  </svg>
);
export default SvgBank;
