import { SVGProps } from 'react';
const SvgFaq = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 25"
    width="1em"
    height="1em"
    {...props}
  >
    <path
      fill="currentColor"
      d="M12 2.107c5.523 0 10 4.477 10 10s-4.477 10-10 10a9.959 9.959 0 0 1-4.644-1.142l-4.29 1.117a.85.85 0 0 1-1.037-1.036l1.116-4.289A9.959 9.959 0 0 1 2 12.107c0-5.523 4.477-10 10-10Zm0 13.5a1 1 0 1 0 0 2 1 1 0 0 0 0-2Zm0-8.75a2.75 2.75 0 0 0-2.75 2.75.75.75 0 0 0 1.493.102l.014-.23a1.25 1.25 0 0 1 2.493.128c0 .54-.135.805-.645 1.332l-.304.31c-.754.784-1.051 1.347-1.051 2.358a.75.75 0 1 0 1.5 0c0-.539.135-.805.645-1.332l.304-.31c.754-.784 1.051-1.347 1.051-2.358A2.75 2.75 0 0 0 12 6.857Z"
    />
  </svg>
);
export default SvgFaq;
