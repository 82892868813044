import { SVGProps } from 'react';
const SvgLogout = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 25"
    width="1em"
    height="1em"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M2.014 7.392c0-2.436 2.117-4.25 4.521-4.25h4.462c2.405 0 4.522 1.814 4.522 4.25v.786a1.15 1.15 0 0 1-2.3 0v-.786c0-.988-.902-1.95-2.222-1.95H6.535c-1.319 0-2.221.962-2.221 1.95v9.366c0 .988.902 1.95 2.221 1.95h4.47c1.316 0 2.214-.958 2.214-1.94v-.794a1.15 1.15 0 1 1 2.3 0v.793c0 2.432-2.114 4.242-4.513 4.242h-4.47c-2.405 0-4.522-1.815-4.522-4.251V7.392Zm6.404 4.682c0-.635.515-1.15 1.15-1.15h8.264l-.591-.543a1.15 1.15 0 0 1 1.556-1.693L20.17 9.95l1.373 1.262a1.15 1.15 0 0 1 0 1.693l-2.745 2.525a1.15 1.15 0 0 1-1.557-1.693l.557-.513h-8.23a1.15 1.15 0 0 1-1.15-1.15Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgLogout;
