import type {
  BooleanMap,
  Feature,
  FeatureRestrictedCountryRegionMap,
} from '@noah-labs/noah-schema';
import type { TpCountryFilterFunction } from './types';

export type TpCountryNeeds = Array<Feature | Array<Feature>>;

type PpSelectCountriesForFeatures =
  | {
      // `needs` is present
      needs: TpCountryNeeds;
      prohibitedCountries: BooleanMap;
      // should be provided when `needs` is present
      restrictedCountries: FeatureRestrictedCountryRegionMap;
    }
  | {
      // `needs` is not present
      needs?: never;
      prohibitedCountries: BooleanMap;
      // should not be provided when `needs` is absent
      restrictedCountries?: never;
    };

export function getfilterCountryForFeature({
  needs,
  prohibitedCountries,
  restrictedCountries,
}: PpSelectCountriesForFeatures): TpCountryFilterFunction {
  return (countryCode) => {
    // Check if the country is prohibited
    if (prohibitedCountries[countryCode]) {
      return false;
    }

    // If feature is not provided, return all non-prohibited countries
    if (!needs) {
      return true;
    }

    // Check for feature support in the country, including wildcard check
    function evaluateCountryFeature(featureOrBoolean: Feature): boolean {
      const countryFeatures = restrictedCountries?.[countryCode];
      if (!countryFeatures) {
        return true;
      }

      const featureSettings = countryFeatures[featureOrBoolean];
      const allRegionsDisabled = featureSettings?.['*'];
      return !allRegionsDisabled;
    }

    return needs.every((condition) => {
      // Handles arrays (OR conditions)
      if (Array.isArray(condition)) {
        return condition.some(evaluateCountryFeature);
      }
      // Handle singles (AND conditions)
      return evaluateCountryFeature(condition);
    });
  };
}
