import { SVGProps } from 'react';
const SvgNoahCircle = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <path
      fill="#F60"
      d="M22 12c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10Z"
    />
    <path
      fill="#fff"
      d="m10.034 7.926 3.4 3.904c.269.32.504.666.701 1.034h.058V7.926h2.7v8.368h-2.921l-3.553-3.896c-.233-.258-.595-.765-.712-1.06h-.059v4.96h-2.71V7.925h3.096Z"
    />
  </svg>
);
export default SvgNoahCircle;
