import type {
  TpCryptoCurrencyUI,
  TpFiatCurrencyUI,
  TpIcon,
} from '@noah-labs/core-web-ui/src/types';
import type {
  BankingSystem,
  CurrencyCode,
  DestinationAddress,
  FiatAmount,
  FiatCurrencyCode,
  FiatPaymentCardScheme,
  FiatPaymentTokenizationSource,
  FullName,
  LedgerTransactionSource,
  Maybe,
  Network,
  StreetAddress,
  TransactionDirection,
  TransactionRecord,
  TransactionStatus,
  TransferDestinationType,
  TransferSourceType,
} from '@noah-labs/noah-schema';
import type BigNumber from 'bignumber.js';

export type TpEnhancedTxData = {
  Avatar: React.ReactElement | null;
  CurrencyIcon: TpIcon | null;
  created: string;
  cryptoAmount: string;
  cryptoCurrency: TpCryptoCurrencyUI;
  direction?: TransactionDirection;
  feeAmount?: string;
  fiatAmount: string | undefined;
  fiatAmountWithFee: string | undefined;
  fiatCurrency: TpFiatCurrencyUI | undefined;
  hasExpired?: boolean;
  /** on/off ramp - checkout (sell, buy) or manual off ramp (bank) */
  isRamp: boolean;
  mainPrimaryContent: string;
  mainSecondaryContent: string;
  status: TransactionStatus;
  statusText: string;
  type?: TpTransactionType;
};

export type TpTransactionItem = {
  Amount: string;
  Created: string;
  Currency: CurrencyCode;
  Destination?: Maybe<DestinationAddress>;
  DestinationAccountID?: Maybe<string>;
  DestinationType?: Maybe<TransferDestinationType>;
  DestinationUsernameDisplay?: Maybe<string>;
  Direction?: Maybe<TransactionDirection>;
  Expiry?: Maybe<string>;
  MarketAmount?: Maybe<FiatAmount>;
  Network: Network;
  NoahID?: Maybe<string>;
  PublicID?: Maybe<string>;
  RequestedAmount?: Maybe<FiatAmount>;
  Source?: Maybe<LedgerTransactionSource>;
  SourceAccountID?: Maybe<string>;
  SourceRecords?: Maybe<Array<TransactionRecord>>;
  SourceType?: Maybe<TransferSourceType>;
  SourceUsernameDisplay?: Maybe<string>;
  Status: TransactionStatus;
  WithdrawOrderID?: Maybe<string>;
};

export type TpCurrencyConverters = {
  cryptoCurrencyFromCode: (code: CurrencyCode) => TpCryptoCurrencyUI;
  fiatCurrencyFromCode: (code: FiatCurrencyCode) => TpFiatCurrencyUI;
};

export type TpTransactionDataQa = {
  noahId?: string | null | undefined;
  orderId?: string | null | undefined;
  publicId?: string | null | undefined;
};

export enum TpCheckoutPaymentStatus {
  failure = 'failure',
  pending = 'pending',
  success = 'success',
}

export enum TpCheckoutPayoutStatus {
  failure = 'failure',
  pending = 'pending',
  success = 'success',
}

export enum TpPaymentMethod {
  ApplePay = 'Apple Pay',
  BankTransfer = 'Bank transfer',
  Card = 'Credit/Debit card',
  GooglePay = 'Google Pay',
}

export type TpPaymentCard = {
  billingAddress: StreetAddress | undefined | null;
  estimateHours?: string | undefined | null;
  /** Checkout token if card is new, DynamoID if card is saved */
  id: string;
  issuer: string | undefined | null;
  last4: string;
  payoutMethod?: TpPaymentMethod;
  payoutSupported?: boolean;
  scheme: FiatPaymentCardScheme | undefined;
  type: 'new' | 'saved';
};

export type TpPaymentBank = {
  accountCurrency: FiatCurrencyCode;
  accountHolderAddress: StreetAddress | undefined | null;
  accountHolderName: FullName | null | undefined;
  accountNumber: string;
  bankCode: string | null | undefined;
  estimateHours?: string | undefined | null;
  id?: string;
  payoutMethod?: TpPaymentMethod;
  payoutSupported?: boolean;
  system: BankingSystem;
  type: 'new' | 'saved';
};

export type TpPaymentMethodChange = {
  card?: TpPaymentCard;
  type: TpPaymentMethod | undefined;
};

export type TpFiatPaymentToken = {
  id: string;
  source: FiatPaymentTokenizationSource;
};

type TpAllowanceReason = 'Balance' | 'PolicyLimit';
/**
 * Outlines the amount and reason for a withdrawal allowance.
 * Takes into account policy limits and balance
 */
export type TpAllowance = {
  amount: BigNumber;
  reason: TpAllowanceReason;
};

export type TpWithdrawalAllowance = {
  /**
   * The maximum amount of crypto that can be withdrawn. Includes balance and limits
   */
  accountAllowanceCrypto: TpAllowance | undefined;
  /**
   * The maximum amount of transactions available for day
   */
  accountDayCountLimitCrypto?: BigNumber | undefined;
  /**
   * The crypto balance of the user
   */
  balanceUserCrypto: BigNumber | undefined;
  /**
   * The maximum amount of crypto that can be withdrawn via LN. Includes balance and limits
   */
  lnAllowanceCrypto: TpAllowance | undefined;
  /**
   * The minimum amount of crypto that can be withdrawn.
   */
  withdrawMinimumSingleCrypto: BigNumber | undefined;
  /**
   * The amount of remaining transactions
   */
  withdrawalRemainingTxs: BigNumber | undefined;
};

export enum TpTransactionType {
  Buy = 'Buy',
  Deposit = 'Deposit',
  Sell = 'Sell',
  Withdraw = 'Withdraw',
}
