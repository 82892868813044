import { SVGProps } from 'react';
const SvgEuropeanUnion = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <path
      fill="#0052B4"
      d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10Z"
    />
    <path
      fill="#FFDA44"
      d="m12 5.913.324.996h1.047l-.847.616.324.997L12 7.906l-.848.616.324-.997-.848-.616h1.048L12 5.913ZM7.696 7.696l.933.475.741-.74-.164 1.034.934.476-1.035.164-.164 1.035-.476-.934-1.034.164.74-.74-.475-.934ZM5.913 12l.997-.324V10.63l.615.847.997-.324-.616.848.616.848-.997-.324-.615.847v-1.047L5.913 12Zm1.783 4.304.475-.933-.74-.741 1.035.164.475-.934.164 1.035 1.035.164-.934.476.164 1.034-.74-.74-.934.475ZM12 18.087l-.324-.997H10.63l.847-.615-.324-.997.848.616.848-.616-.324.997.848.615h-1.048l-.324.997Zm4.304-1.783-.933-.475-.741.74.164-1.035-.934-.475 1.035-.164.164-1.035.476.934 1.034-.164-.74.74.475.934ZM18.087 12l-.997.324v1.047l-.615-.847-.997.324.616-.848-.616-.848.997.324.615-.848v1.048l.997.324Zm-1.783-4.304-.475.933.74.741-1.034-.164-.476.934-.164-1.035-1.035-.164.934-.476-.164-1.034.74.74.934-.475Z"
    />
  </svg>
);
export default SvgEuropeanUnion;
