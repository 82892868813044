import { SVGProps } from 'react';
const SvgStarBadge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <path
      fill="currentColor"
      d="M12 23.9c3.2 0 6.2-1.3 8.5-3.5 2.2-2.2 3.5-5.3 3.5-8.5s-1.3-6.2-3.5-8.5C18.2 1.3 15.2 0 12 0 8.9 0 5.8 1.3 3.6 3.5 1.3 5.8.1 8.8.1 12s1.3 6.2 3.5 8.5c2.2 2.2 5.3 3.4 8.4 3.4zM16 13c-.2.2-.2.4-.2.6l.8 4.7-4.2-2.2c-.2-.1-.4-.1-.7 0l-4.2 2.2.8-4.7c0-.2 0-.5-.2-.6L4.7 9.8l4.7-.7c.3-.1.5-.2.6-.4l2-4.3 2.1 4.3c.2.2.4.3.6.3l4.7.7L16 13z"
    />
  </svg>
);
export default SvgStarBadge;
