import { SVGProps } from 'react';
const SvgEye = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 25"
    width="1em"
    height="1em"
    {...props}
  >
    <path
      fill="currentColor"
      d="M14.997 12.106c0 1.589-1.344 2.877-3.002 2.877-1.659 0-3.003-1.288-3.003-2.877 0-1.589 1.344-2.877 3.003-2.877 1.658 0 3.002 1.288 3.002 2.877ZM12 6.116c-1.717.008-3.497.426-5.182 1.226a13.198 13.198 0 0 0-3.528 2.57c-.52.549-1.183 1.345-1.29 2.195.013.736.802 1.645 1.29 2.196.992 1.035 2.18 1.884 3.528 2.57A12.147 12.147 0 0 0 12 18.099c1.719-.008 3.498-.43 5.181-1.226a13.202 13.202 0 0 0 3.53-2.57c.519-.55 1.182-1.346 1.289-2.196-.013-.736-.802-1.644-1.29-2.196-.992-1.035-2.18-1.883-3.529-2.569A12.217 12.217 0 0 0 12 6.116Zm-.001 1.488c2.602 0 4.711 2.016 4.711 4.504 0 2.487-2.11 4.504-4.711 4.504-2.602 0-4.712-2.017-4.712-4.504 0-2.488 2.11-4.504 4.712-4.504Z"
    />
  </svg>
);
export default SvgEye;
