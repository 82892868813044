import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';
import { PrimaryButton } from '../buttons/PrimaryButton';
import { SquareWarningIcon } from '../icons';
import { AppContainer } from '../layout/AppContainer';
import { AppHeader } from '../layout/AppHeader';
import { SceneIcon } from '../scene/SceneIcon';
import { SceneMain } from '../scene/SceneMain';
import { SceneParagraph, SceneTitleLarge } from '../scene/Typography';

export type PpErrorPage = {
  AppHeaderSlot?: React.ReactElement | null;
  FooterSlot?: React.ReactElement;
  IconSlot?: React.ReactElement;
  helpText?: string;
  message?: string;
  pageTitle?: string;
};

const DefaultAppHeader = <AppHeader backButton />;
export function ErrorPage({
  FooterSlot,
  AppHeaderSlot = DefaultAppHeader,
  message,
  helpText,
  IconSlot,
  pageTitle = message,
}: PpErrorPage): React.ReactElement {
  const history = useHistory();

  return (
    <AppContainer
      AppFooterSlot={
        FooterSlot || <PrimaryButton onClick={(): void => history.goBack()}>Go back</PrimaryButton>
      }
      AppHeaderSlot={AppHeaderSlot === null ? undefined : AppHeaderSlot}
      dataQa="start-over"
    >
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <SceneMain>
        {IconSlot ?? (
          <SceneIcon>
            <SquareWarningIcon color="error" />
          </SceneIcon>
        )}
        <SceneTitleLarge>{message}</SceneTitleLarge>
        <SceneParagraph>{helpText}</SceneParagraph>
      </SceneMain>
    </AppContainer>
  );
}
