import { SVGProps } from 'react';
const SvgUnitedStates = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <path
      fill="#F0F0F0"
      d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10Z"
    />
    <path
      fill="#D80027"
      d="M11.565 12H22c0-.902-.12-1.777-.345-2.609h-10.09v2.61Zm0-5.217h8.967a10.055 10.055 0 0 0-2.307-2.609h-6.66v2.609ZM12 22a9.957 9.957 0 0 0 6.225-2.174H5.775A9.957 9.957 0 0 0 12 22Zm-8.532-4.782h17.064a9.94 9.94 0 0 0 1.123-2.61H2.345a9.94 9.94 0 0 0 1.123 2.61Z"
    />
    <path
      fill="#0052B4"
      d="M6.632 3.562h.911l-.847.615.324.997-.848-.616-.848.616.28-.86c-.746.62-1.4 1.35-1.94 2.16h.293l-.54.393c-.084.14-.165.282-.242.427l.258.793-.481-.35a9.81 9.81 0 0 0-.327.777l.284.874h1.048l-.848.616.324.996-.848-.616-.508.37C2.027 11.161 2 11.577 2 12h10V2a9.953 9.953 0 0 0-5.368 1.562ZM7.02 11l-.848-.616-.848.616.324-.996-.847-.616h1.047l.324-.997.324.997h1.047l-.847.616.324.996Zm-.324-3.91.324.997-.848-.616-.848.616.324-.997-.847-.615h1.047l.324-.997.324.997h1.047l-.847.615Zm3.91 3.91-.847-.616L8.91 11l.324-.996-.848-.616h1.048l.324-.997.324.997h1.047l-.847.616.324.996Zm-.323-3.91.324.997-.848-.616-.848.616.324-.997-.848-.615h1.048l.324-.997.324.997h1.047l-.847.615Zm0-2.913.324.997-.848-.616-.848.616.324-.997-.848-.615h1.048l.324-.997.324.997h1.047l-.847.615Z"
    />
  </svg>
);
export default SvgUnitedStates;
