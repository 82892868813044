import { SVGProps } from 'react';
const SvgInfo = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 25"
    width="1em"
    height="1em"
    {...props}
  >
    <path
      fill="currentColor"
      d="M12 22.107a10 10 0 1 1 0-20 10 10 0 0 1 0 20Zm0-15a1 1 0 1 0 0 2 1 1 0 0 0 0-2Zm1 4a1 1 0 0 0-2 0v5a1 1 0 0 0 2 0v-5Z"
    />
  </svg>
);
export default SvgInfo;
